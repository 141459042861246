import React, {useState, useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import usePrevious from '../../helpers/UsePrevious';
import ActionBarItem from './ActionBarItem/ActionBarItem';
import Navigation from '../../config/Navigation';
import HTMLElement from '../../helpers/HTMLElement';
import Storage from '../../helpers/Storage';
import Viewport from '../../helpers/Viewport';
import Activity from '../../helpers/Activity';
import {ACTION_BAR_ITEMS} from '../../config/App';
import moveBlackIcon from './images/move-black.svg';
import moveWhiteIcon from './images/move-white.svg';
import homeBlackIcon from './images/home-black.svg';
import homeWhiteIcon from './images/home-white.svg';
import homeGrayIcon from './images/home-gray.svg';
import searchBlackIcon from './images/search-black.svg';
import searchWhiteIcon from './images/search-white.svg';
import searchGrayIcon from './images/search-gray.svg';
import cartBlackIcon from './images/cart-black.svg';
import cartWhiteIcon from './images/cart-white.svg';
import cartGrayIcon from './images/cart-gray.svg';
import helpBlackIcon from './images/support-black.png';
import helpWhiteIcon from './images/support-white.png';
import helpGrayIcon from './images/support-gray.png';
import surveyBlackIcon from './images/rate-black.png';
import surveyWhiteIcon from './images/rate-white.png';
import surveyGrayIcon from './images/rate-gray.png';
import backBlackIcon from './images/back-black.svg';
import backWhiteIcon from './images/back-white.svg';
import backGrayIcon from './images/back-gray.svg';
import './ActionBar.css';

function ActionBar({
    id,
    viewRef,
    parentRef,
    mode,
    startAt,
    shoppingCart,
    activeSearch,
    activeHelp,
    hasHelpUrl,
    activeSurvey,
    hasSurveyUrl,
    activeCart,
    searchAction,
    helpAction,
    surveyAction,
    cartAction,
    style,
    metadata
}, ref) {
    const viewport = Viewport.dimensions;
    const actionBarRef = useRef(ref);
    const navigate = useNavigate();
    const previousStartAt = usePrevious(startAt);
    const previousActiveSearch = usePrevious(activeSearch);
    const previousActiveHelp = usePrevious(activeHelp);
    const previousActiveSurvey = usePrevious(activeSurvey);
    const previousActiveCart = usePrevious(activeCart);

    useEffect(() => {
        const searchChanged = previousActiveSearch !== activeSearch;
        const helpChanged = previousActiveHelp !== activeHelp;
        const surveyChanged = previousActiveSurvey !== activeSurvey;
        const cartChanged = previousActiveCart !== activeCart;
        if (searchChanged || helpChanged || surveyChanged || cartChanged) {
            let newActions = {...actions};
            if (searchChanged) actions.search.active = activeSearch;
            if (helpChanged && actions.help) actions.help.active = activeHelp;
            if (surveyChanged && actions.survey) actions.survey.active = activeSurvey;
            if (cartChanged) actions.cart.active = activeCart;
            setActions(newActions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSearch, activeHelp, activeSurvey, activeCart]);

    const [isDragging, setIsDragging] = useState(false);
    const [dragStartY, setDragStartY] = useState(undefined);
    const [mousePositionY, setMousePositionY] = useState(0);
    const [dragMovementY, setDragMovementY] = useState(0);
    const [actionBarPositionY, setActionBarPositionY] = useState(startAt || 0);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        setTimeout(() => {
            handleResize();
        });
        return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleResize = () => {
        const actionBarProperties = actionBarRef && actionBarRef.current ? HTMLElement.getDisplayProperties(actionBarRef.current) : undefined;
        const maxPositionY = actionBarProperties ? viewport.height - actionBarProperties.height : undefined;
        const positionY = actionBarPositionY + dragMovementY;
        if (positionY > maxPositionY) {
            setActionBarPositionY(maxPositionY);
        }
    };

    useEffect(() => {
        if (previousStartAt !== startAt) setActionBarPositionY(startAt);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startAt]);

    const handleDragStart = (event) => {
        Activity.log(metadata, 'action-bar', 'move-button', 'reposition', undefined);
        const newDragStartY = event.pageY !== undefined ? event.pageY : event.touches[0].pageY;
        setIsDragging(true);
        setDragStartY(newDragStartY);
    };

    useEffect(() => {
        if (isDragging) onDragStart(dragStartY);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDragging, dragStartY]);

    const onDragStart = () => {
        document.body.addEventListener('mousemove', handleDrag);
        document.body.addEventListener('touchmove', handleDrag);
        document.body.addEventListener('mouseup', handleDragEnd);
        document.body.addEventListener('mouseleave', handleDragEnd);
        document.body.addEventListener('touchend', handleDragEnd);
        // if (viewRef && viewRef.current) viewRef.current.style.overflowY = 'hidden'; // TODO: Check if this is necessary (Disables scroll when the action bar is being dragged).
    };

    const handleDrag = (event) => {
        const newMousePositionY = event.pageY !== undefined ? event.pageY : event.touches[0].pageY;
        setMousePositionY(newMousePositionY);
    };

    useEffect(() => {
        if (isDragging) onDrag(mousePositionY);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mousePositionY]);

    const onDrag = (positionY) => {
        let dragMovementY = positionY - dragStartY;
        const actionBarProps = HTMLElement.getDisplayProperties(actionBarRef.current);
        const parentProps = HTMLElement.getDisplayProperties(parentRef.current);
        const actionBarNewPositionY = actionBarPositionY + dragMovementY;
        const actionBarBottomY = actionBarNewPositionY + actionBarProps.height;
        if (actionBarNewPositionY < 0) dragMovementY = actionBarPositionY * -1;
        if (actionBarBottomY > parentProps.height) dragMovementY = parentProps.height - actionBarProps.height - actionBarPositionY;
        setDragMovementY(dragMovementY);
    };

    const handleDragEnd = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        if (!isDragging) onDragEnd();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDragging]);

    const onDragEnd = () => {
        const newActionBarPositionY = actionBarPositionY + dragMovementY;
        setDragStartY(undefined);
        setDragMovementY(0);
        setActionBarPositionY(newActionBarPositionY);
        Storage.setActionBarPosition(newActionBarPositionY);
        document.body.removeEventListener('mouseup', handleDragEnd);
        document.body.removeEventListener('mouseleave', handleDragEnd);
        document.body.removeEventListener('touchend', handleDragEnd);
        document.body.removeEventListener('mousemove', handleDrag);
        document.body.removeEventListener('touchmove', handleDrag);
        // if (viewRef && viewRef.current) viewRef.current.style.overflowY = 'auto'; // TODO: Check if this is necessary (Enables scroll when the action bar is being dragged).
    };

    const handleHomeAction = () => {
        Activity.log(metadata, 'action-bar', 'home-button', 'redirect', undefined);
        const url = Navigation.getHomeUrl();
        navigate(url);
    };

    const handleBackAction = () => {
        Activity.log(metadata, 'action-bar', 'back-button', 'redirect', undefined);
        navigate(-1);
    };

    const handleSearchAction = () => {
        if (searchAction) {
            Activity.log(metadata, 'action-bar', 'search-button', 'open', undefined);
            searchAction();
        }
    };

    const handleHelpAction = () => {
        if (helpAction) {
            Activity.log(metadata, 'action-bar', 'help-button', 'open', undefined);
            helpAction();
        }
    };

    const handleSurveyAction = () => {
        if (surveyAction) {
            Activity.log(metadata, 'action-bar', 'survey-button', 'open', undefined);
            surveyAction();
        }
    };

    const handleCartAction = () => {
        if (cartAction) {
            Activity.log(metadata, 'action-bar', 'cart-button', 'open', undefined);
            cartAction();
        }
    };

    const moveAction = {label: 'Mover', icon: moveBlackIcon, activeIcon: moveWhiteIcon};
    const moveStyle = style && style.move ? style.move : {};

    const buildActions = () => {
        let actionList = {};
        actionList.home   = {active: false, label: 'Inicio', icon: homeBlackIcon, activeIcon: homeWhiteIcon, disabledIcon: homeGrayIcon, _function: handleHomeAction};
        actionList.search = {active: activeSearch, label: 'Buscar', icon: searchBlackIcon, activeIcon: searchWhiteIcon, disabledIcon: searchGrayIcon, _function: handleSearchAction};
        if (hasHelpUrl) actionList.help = {active: activeHelp, label: 'Ayuda', icon: helpBlackIcon, activeIcon: helpWhiteIcon, disabledIcon: helpGrayIcon, _function: handleHelpAction};
        if (hasSurveyUrl) actionList.survey = {active: activeSurvey, label: 'Encuesta', icon: surveyBlackIcon, activeIcon: surveyWhiteIcon, disabledIcon: surveyGrayIcon, _function: handleSurveyAction};
        actionList.cart   = {active: activeCart, label: 'Carro', icon: cartBlackIcon, activeIcon: cartWhiteIcon, disabledIcon: cartGrayIcon, _function: handleCartAction};
        actionList.back   = {active: false, label: 'Volver', icon: backBlackIcon, activeIcon: backWhiteIcon, disabledIcon: backGrayIcon, _function: handleBackAction};
        return actionList;
    };

    const [actions, setActions] = useState(buildActions());

    useEffect(() => {
        setActions(buildActions());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasHelpUrl, hasSurveyUrl]);

    const getActionsByMode = () => {
        switch (mode) {
            case 'catalog':
                return ACTION_BAR_ITEMS['catalog'];
            case 'products-list':
                return ACTION_BAR_ITEMS['products-list'];
            case 'product':
                return ACTION_BAR_ITEMS['product'];
            default:
                return [];
        }
    };

    const getCartItemsCount = () => {
        return shoppingCart && shoppingCart.items ? shoppingCart.items.reduce((accumulator, item) => (accumulator + item.quantity), 0) : 0;
    };

    const actionBarStyle = () => {
        const validBackgroundColor = moveStyle['backgroundColor'] || '#FFFFFF';
        return {
            background: validBackgroundColor,
            top: actionBarPositionY + dragMovementY,
            left: 0
        };
    };

    const renderMoveAction = () => {
        return (
            <div className='action-bar-move-action' onMouseDown={handleDragStart} onTouchStart={handleDragStart}>
                <div className='action-bar-move-wrapper'>
                    <ActionBarItem
                        key={`${id}-item-move`}
                        active={isDragging}
                        enabled={true}
                        label={moveAction.label}
                        icon={moveStyle['icon'] || moveAction.icon}
                        activeIcon={moveStyle['activeIcon'] || moveAction.activeIcon}
                        disabledIcon={undefined}
                        labelColor={moveStyle['labelColor']}
                        backgroundColor={moveStyle['backgroundColor']}
                        activeLabelColor={moveStyle['activeLabelColor']}
                        activeBackgroundColor={moveStyle['activeBackgroundColor']}
                        disabledLabelColor={undefined}
                        disabledBackgroundColor={undefined}
                        indicatorBackgroundColor={moveStyle['indicatorBackgroundColor']}
                        indicatorLabelColor={moveStyle['indicatorLabelColor']}
                        showNotification={false}
                        notificationNumber={undefined}
                        onClick={() => {}}
                    />
                </div>
            </div>
        );
    };

    const renderModeActions = () => {
        const enabledActions = getActionsByMode();
        return Object.keys(actions).map((key, index) => {
            const action = actions[key];
            const actionStyle = style && style[key] ? style[key] : {};
            let enabled = !!enabledActions.find(name => name === key);
            let showNotification = false;
            let notificationNumber = undefined;
            if (key === 'cart') {
                showNotification = true;
                notificationNumber = getCartItemsCount();
            }
            return (
                <ActionBarItem
                    key={`${id}-item-${index}`}
                    active={action.active}
                    enabled={enabled}
                    label={action.label}
                    icon={actionStyle['icon'] || action.icon}
                    activeIcon={actionStyle['activeIcon'] || action.activeIcon}
                    disabledIcon={actionStyle['disabledIcon'] || action.disabledIcon}
                    labelColor={actionStyle['labelColor']}
                    backgroundColor={actionStyle['backgroundColor']}
                    activeLabelColor={actionStyle['activeLabelColor']}
                    activeBackgroundColor={actionStyle['activeBackgroundColor']}
                    disabledLabelColor={actionStyle['disabledLabelColor']}
                    disabledBackgroundColor={actionStyle['disabledBackgroundColor']}
                    indicatorBackgroundColor={actionStyle['indicatorBackgroundColor']}
                    indicatorLabelColor={actionStyle['indicatorLabelColor']}
                    showNotification={showNotification}
                    notificationNumber={notificationNumber}
                    onClick={action._function}
                />
            );
        });
    };

    // noinspection JSValidateTypes
    return (
        <div id={id} ref={actionBarRef} className='action-bar' style={actionBarStyle()}>
            {renderMoveAction()}
            <div className='action-bar-items'>
                {renderModeActions()}
            </div>
        </div>
    );
}

export default ActionBar;