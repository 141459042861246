import Debug from './Debug';
import Environment from '../config/Environment';
import Storage from './Storage';
import {
    CLIENT_CHECKOUT_URL,
    UPDATE_PRODUCT_URL,
    GET_CLIENT_ENTITY_URL,
    GET_SII_ENTITY_URL,
    GET_STATES_URL,
    GET_DISTRICTS_URL,
    GET_CITIES_URL,
    GET_ACTIVITIES_URL,
    GET_HQ_DATA_URL
} from '../config/App';

export default class WebServices {
    static getClientShoppingCartUrl = (clientId, storeId, shoppingCart, externalSale) => {
        const environment = Environment.current;
        const url = `${CLIENT_CHECKOUT_URL}?environment=${environment}&client=${clientId}`;
        let body = shoppingCart.toCheckout();
        body.store_id = storeId;
        body.external_sale_flow = externalSale;
        return fetch(url, {method: 'POST', mode: 'cors', cache: 'no-cache', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)})
            .then(response => this.onResponse(response))
            .then(response => {
                if (response) return {id: response['id'], url: response['checkout_url']};
                else return undefined;
            });
    };

    static asyncProductUpdate = (clientId, productId) => {
        const environment = Environment.current;
        const url = `${UPDATE_PRODUCT_URL}?environment=${environment}&client=${clientId}&persist=true&skus[]=${productId}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return !!response;
            });
    };

    static getClientEntity = (pin) => {
        const environment = Environment.current;
        const client = Storage.getClient();
        const url = `${GET_CLIENT_ENTITY_URL}?p=g7InbLgZXnAiax0kzLOtLrPSruaepVq9&environment=${environment}&client=${client}&pin=${encodeURIComponent(pin)}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return response;
            });
    };

    static getSiiEntity = (pin) => {
        const url = `${GET_SII_ENTITY_URL}?p=g7InbLgZXnAiax0kzLOtLrPSruaepVq9&pin=${encodeURIComponent(pin)}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return response;
            });
    };

    static getActivities = () => {
        const environment = Environment.current;
        const client = Storage.getClient();
        const url = `${GET_ACTIVITIES_URL}?p=g7InbLgZXnAiax0kzLOtLrPSruaepVq9&environment=${environment}&client=${client}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return response ? response : [];
            });
    };

    static getStates = () => {
        const environment = Environment.current;
        const client = Storage.getClient();
        const url = `${GET_STATES_URL}?p=g7InbLgZXnAiax0kzLOtLrPSruaepVq9&environment=${environment}&client=${client}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return response ? response : [];
            });
    };

    static getDistricts = () => {
        const environment = Environment.current;
        const client = Storage.getClient();
        const url = `${GET_DISTRICTS_URL}?p=g7InbLgZXnAiax0kzLOtLrPSruaepVq9&environment=${environment}&client=${client}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return response ? response : [];
            });
    };

    static getCities = () => {
        const environment = Environment.current;
        const client = Storage.getClient();
        const url = `${GET_CITIES_URL}?p=g7InbLgZXnAiax0kzLOtLrPSruaepVq9&environment=${environment}&client=${client}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return response ? response : [];
            });
    };

    static getHQData = () => {
        const environment = Environment.current;
        const client = Storage.getClient();
        const url = `${GET_HQ_DATA_URL}?p=g7InbLgZXnAiax0kzLOtLrPSruaepVq9&environment=${environment}&client=${client}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return response;
            });
    };

    static onResponse = (response) => {
        if (response.ok) {
            try {
                return response.json();
            } catch (error) {
                Debug.printToLog('error', `Unable to parse JSON response for endpoint ${response.url}`);
                return undefined;
            }
        } else {
            // Debug.printToLog('error', `Server returned status code ${response.status} for endpoint: ${response.url}`); // Redundant because Chrome already prints this information.
            return undefined;
        }
    };
}