import React, {useState, useEffect, useRef, Children} from 'react';
import {useNavigate} from "react-router-dom";
import usePrevious from '../../helpers/UsePrevious';
import ActionBar from '../ActionBar/ActionBar';
import ActionBarMobile from '../ActionBarMobile/ActionBarMobile';
import AccessibilityButton from '../AccessibilityButton/AccessibilityButton';
import SearchModal from '../SearchModal/SearchModal';
import HelpModal from '../HelpModal/HelpModal';
import SurveyModal from '../SurveyModal/SurveyModal';
import CrossSellModal from '../CrossSellModal/CrossSellModal';
import PaymentModal from '../PaymentModal/PaymentModal';
import InactivityModal from "../InactivityModal/InactivityModal";
import Slider from '../Slider/Slider';
import Button from '../Button/Button';
import ShoppingCart from '../ShoppingCart/ShoppingCart';
import Viewport from '../../helpers/Viewport';
import Storage from '../../helpers/Storage';
import Activity from '../../helpers/Activity';
import Navigation from '../../config/Navigation';
import Environment from '../../config/Environment';
import {CONFIG_PRESS_TIME} from '../../config/App';
import {algoliaSearchClient, itemSearchSettings} from '../../config/AlgoliaSearch';
import configIcon from './images/config.png';
import caretIcon from './images/caret.png';
import './View.css';

function View({
    client,
    instance,
    catalog,
    session,
    store,
    style,
    showConfigModal,
    zoomLevel,
    showPromotionalVideo,
    showActionBar,
    actionBarMode,
    showAccessibilityButton,
    accessibilityMode,
    showTopSpacer,
    showBottomSpacer,
    onAccessibilityButtonClick,
    remainingTimeUntilReset,
    startPromotionalVideoTimer,
    stopPromotionalVideoTimer,
    showInactivityModal,
    openInactivityModal,
    closeInactivityModal,
    resetKiosk,
    shoppingCart,
    showShoppingCart,
    openShoppingCart,
    closeShoppingCart,
    updateShoppingCart,
    addItemToCart,
    removeItemFromCart,
    openConfigModal,
    closeConfigModal,
    changeZoomLevel,
    onConfigClear,
    algoliaInsights,
    children
}) {
    const viewRef = useRef(null);
    const actionBarTrackRef = useRef(null);
    const intervalRef = useRef(null);
    const [showCrossSellModal, setShowCrossSellModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [paymentMode, setPaymentMode] = useState(undefined);
    const [isConfigButtonPressed, setIsConfigButtonPressed] = useState(false);
    const [elapsedConfigTime, setElapsedConfigTime] = useState(0);
    const [currentZoomLevel, setCurrentZoomLevel] = useState(zoomLevel);
    const [drawActionBar, setDrawActionBar] = useState(true);
    const [isActionBarMobileOpen, setIsActionBarMobileOpen] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [showSurvey, setShowSurvey] = useState(false);
    const previousShowPromotionalVideo = usePrevious(showPromotionalVideo);
    const previousConfigButtonPressed = usePrevious(isConfigButtonPressed);
    const previousShowTopSpacer = usePrevious(showTopSpacer);
    const viewport = Viewport.dimensions;
    const navigate = useNavigate();

    const inSmallMode = () => {
        return viewport.width < 800 || viewport.height < 1100;
    };

    useEffect(() => {
        if (onConfigClear) {
            if (!previousConfigButtonPressed && isConfigButtonPressed) startConfigTimer();
            if (previousConfigButtonPressed && !isConfigButtonPressed) stopConfigTimer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConfigButtonPressed]);

    useEffect(() => {
        if (onConfigClear) {
            const remainingTime = CONFIG_PRESS_TIME - (elapsedConfigTime || 0);
            if (remainingTime <= 0) {
                stopConfigTimer();
                if (stopPromotionalVideoTimer) stopPromotionalVideoTimer();
                if (openConfigModal) openConfigModal();
                const metadata = getMetadata();
                Activity.log(metadata, 'kiosk', 'hidden-button', 'open-config', undefined);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elapsedConfigTime]);

    useEffect(() => {
        if ((!previousShowTopSpacer && showTopSpacer) || (previousShowTopSpacer && !showTopSpacer)) viewRef.current.scrollTo({top: 0, behavior: 'smooth'});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showTopSpacer]);

    useEffect(() => {
        if (!previousShowPromotionalVideo && showPromotionalVideo) {
            setTimeout(() => {
                const url = Navigation.getHomeUrl();
                navigate(url);
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showPromotionalVideo]);

    useEffect(() => {
        setCurrentZoomLevel(zoomLevel);
    }, [zoomLevel]);

    const hasHelpUrl = !!style && !!style['global'] && !!style['global']['helpModal'] && !!style['global']['helpModal']['helpUrl'];
    const hasSurveyUrl = !!style && !!style['global'] && !!style['global']['surveyModal'] && !!style['global']['surveyModal']['surveyUrl'];

    const getMetadata = () => {
        return {client: client, instance: instance, catalog: catalog, store: store, session: session, algoliaInsights: algoliaInsights};
    };

    const handleAccessibilityButtonClick = () => {
        if (onAccessibilityButtonClick) {
            const metadata = getMetadata();
            Activity.log(metadata, 'accessibility-mode', 'accessibility-button', accessibilityMode ? 'disable' : 'enable', undefined);
            onAccessibilityButtonClick();
        }
    };

    const handleConfigButtonPress = () => {
        setIsConfigButtonPressed(true);
    };

    const handleConfigButtonRelease = () => {
        setIsConfigButtonPressed(false);
    };

    const handleCurrentZoomLevelChange = (value) => {
        setCurrentZoomLevel(value / 100);
    };

    const handleCurrentZoomLevelRelease = (value) => {
        if (changeZoomLevel) changeZoomLevel(value / 100, true);
    };

    const handleConfigClear = () => {
        if (onConfigClear) {
            const metadata = getMetadata();
            Activity.log(metadata, 'kiosk', 'session', 'end', undefined); // Session close event.
            onConfigClear();
        }
    };

    const handleConfigClose = () => {
        if (startPromotionalVideoTimer) startPromotionalVideoTimer();
        if (closeConfigModal) closeConfigModal();
        setIsConfigButtonPressed(false);
        const metadata = getMetadata();
        Activity.log(metadata, 'config-modal', 'custom-close-button', 'close', undefined);
    };

    const startConfigTimer = () => {
        intervalRef.current = setInterval(() => {
            setElapsedConfigTime(elapsedConfigTime => elapsedConfigTime + 1);
        }, 1000);
    };

    const stopConfigTimer = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            setElapsedConfigTime(0);
        }
    };

    const getActionBarStartPoint = () => {
        const position = Storage.getActionBarPosition();
        return position !== undefined ? position : Math.round(viewport.height * 0.2);
    };

    const openSearch = () => {
        setShowSearch(true);
    };

    const closeSearch = () => {
        setShowSearch(false);
    };

    const handleSearchChange = (query, autocompleteIndex) => {
        const environment = Environment.current;
        const indexName = `${environment}_${client}_items`;
        const index = algoliaSearchClient.initIndex(indexName);
        const settings = itemSearchSettings(catalog, undefined);
        index.search(query, settings)
            .then((response) => {
                const queryId = response.queryID;
                let url;
                if (response.hits.length === 1) {
                    url = Navigation.getProductUrl(response.hits[0]['objectID'], undefined, queryId);
                    if (session && session.id) algoliaInsights.clickedObjectIDsAfterSearch(session.id, queryId, [response.hits[0]['objectID']], [autocompleteIndex]);
                } else {
                    url = Navigation.getSearchUrl(query);
                }
                Navigation.forceRedirect(url);
            });
    };

    const openHelp = () => {
        setShowHelp(true);
    };

    const closeHelp = () => {
        setShowHelp(false);
    };

    const openSurvey = () => {
        setShowSurvey(true);
    };

    const closeSurvey = () => {
        setShowSurvey(false);
    };

    const openCrossSellModal = () => {
        setShowCrossSellModal(true);
    };

    const closeCrossSellModal = () => {
        setShowCrossSellModal(false);
    };

    const openPaymentModal = () => {
        const paymentMode = style && style['global'] && style['global']['checkout'] && style['global']['checkout']['paymentMode'] ? style['global']['checkout']['paymentMode'] : 'external-checkout';
        setPaymentMode(paymentMode);
        setShowPaymentModal(true);
    };

    const showBudgetPrintOption = () => {
        return style && style['global'] && style['global']['shoppingCart'] && style['global']['shoppingCart']['showAdditionalBudgetPrintOption'] ? style['global']['shoppingCart']['showAdditionalBudgetPrintOption'] : false;
    };

    const openBudgetPrintModal = () => {
        setPaymentMode('budget-print');
        setShowPaymentModal(true);
    };

    const closePaymentModal = () => {
        setShowPaymentModal(false);
        setPaymentMode(undefined);
    };

    const openActionBar = () => {
        setDrawActionBar(true);
    };

    const closeActionBar = () => {
        setDrawActionBar(false);
    };

    const openActionBarMobile = () => {
        const metadata = getMetadata();
        Activity.log(metadata, 'action-bar-mobile', 'toggle-button', 'open', undefined);
        setIsActionBarMobileOpen(true);
    };

    const closeActionBarMobile = (closeType) => {
        const metadata = getMetadata();
        if (closeType !== 'silent')
            Activity.log(metadata, 'action-bar-mobile', closeType, 'close', undefined);
        setIsActionBarMobileOpen(false);
    };

    const viewStyle = () => {
        return {
            zoom: zoomLevel
        };
    };

    const configButtonStyle = () => {
        return {
            opacity: isConfigButtonPressed ? 1 : 0
        };
    };

    const configOverlayStyle = () => {
        return {
            width: isConfigButtonPressed ? '100%' : 0,
            height: isConfigButtonPressed ? '100%' : 0
        };
    };

    const topSpacerStyle = () => {
        return {
            height: showTopSpacer ? '30%' : 0
        };
    };

    const bottomSpacerStyle = () => {
        return {
            height: showBottomSpacer ? '50%' : 0
        };
    };

    const renderActionBar = () => {
        const metadata = getMetadata();
        const actionBarStyle = style && style['global'] && style['global']['actionBar'] ? style['global']['actionBar'] : undefined;
        if (!inSmallMode()) {
            return (
                <div ref={actionBarTrackRef} className={`view-action-bar-track ${drawActionBar ? 'visible' : 'hidden'}`}>
                    <ActionBar
                        id='action-bar'
                        viewRef={viewRef}
                        parentRef={actionBarTrackRef}
                        mode={actionBarMode}
                        startAt={getActionBarStartPoint()}
                        shoppingCart={shoppingCart}
                        activeSearch={showSearch}
                        activeHelp={showHelp}
                        hasHelpUrl={hasHelpUrl}
                        activeSurvey={showSurvey}
                        hasSurveyUrl={hasSurveyUrl}
                        activeCart={showShoppingCart}
                        searchAction={openSearch}
                        helpAction={openHelp}
                        surveyAction={openSurvey}
                        cartAction={openShoppingCart}
                        style={actionBarStyle}
                        metadata={metadata}
                    />
                </div>
            );
        } else {
            return (
                <React.Fragment>
                    <div className={`view-action-bar-mobile-toggle ${drawActionBar ? 'visible' : 'hidden'}`} onClick={isActionBarMobileOpen ? () => closeActionBarMobile('toggle-button') : openActionBarMobile}>
                        <img className='view-action-bar-mobile-icon' src={`${caretIcon}`} alt='toggle' draggable={false}/>
                    </div>
                    {isActionBarMobileOpen && (
                        <ActionBarMobile
                            id='action-bar-mobile'
                            mode={actionBarMode}
                            shoppingCart={shoppingCart}
                            activeSearch={showSearch}
                            activeHelp={showHelp}
                            hasHelpUrl={hasHelpUrl}
                            activeSurvey={showSurvey}
                            hasSurveyUrl={hasSurveyUrl}
                            activeCart={showShoppingCart}
                            searchAction={openSearch}
                            helpAction={openHelp}
                            surveyAction={openSurvey}
                            cartAction={openShoppingCart}
                            closeAction={closeActionBarMobile}
                            style={actionBarStyle}
                            metadata={metadata}
                        />
                    )}
                </React.Fragment>
            );
        }
    };

    const renderAccessibilityButton = () => {
        return (
            <div className='view-accessibility-button'>
                <AccessibilityButton
                    icon={accessibilityButtonIcon}
                    backgroundColor={accessibilityButtonBackgroundColor}
                    action={handleAccessibilityButtonClick}
                />
            </div>
        );
    };

    const renderSearchModal = () => {
        const inputBorderColor = style && style['global'] && style['global']['searchBox'] && style['global']['searchBox']['inputBorderColor'] ? style['global']['searchBox']['inputBorderColor'] : undefined;
        const actionButtonBackgroundColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['backgroundColor'] ? style['global']['actionButton']['backgroundColor'] : undefined;
        const actionButtonBorderColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['borderColor'] ? style['global']['actionButton']['borderColor'] : undefined;
        const actionButtonLabelColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['labelColor'] ? style['global']['actionButton']['labelColor'] : undefined;
        const cancelButtonBackgroundColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['backgroundColor'] ? style['global']['cancelButton']['backgroundColor'] : undefined;
        const cancelButtonBorderColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['borderColor'] ? style['global']['cancelButton']['borderColor'] : undefined;
        const cancelButtonLabelColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['labelColor'] ? style['global']['cancelButton']['labelColor'] : undefined;
        return (
            <SearchModal
                client={client}
                catalog={catalog}
                inputBorderColor={inputBorderColor}
                actionButtonBackgroundColor={actionButtonBackgroundColor}
                actionButtonBorderColor={actionButtonBorderColor}
                actionButtonLabelColor={actionButtonLabelColor}
                cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                cancelButtonBorderColor={cancelButtonBorderColor}
                cancelButtonLabelColor={cancelButtonLabelColor}
                onSearch={handleSearchChange}
                onClose={closeSearch}
                metadata={getMetadata()}
            />
        );
    };

    const renderHelpModal = () => {
        const helpUrl = style && style['global'] && style['global']['helpModal'] && style['global']['helpModal']['helpUrl'] ? style['global']['helpModal']['helpUrl'] : undefined;
        const qrScanImage = style && style['global'] && style['global']['helpModal'] && style['global']['helpModal']['helpModalQrScanImage'] ? style['global']['helpModal']['helpModalQrScanImage'] : undefined;
        const cancelButtonBackgroundColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['backgroundColor'] ? style['global']['cancelButton']['backgroundColor'] : undefined;
        const cancelButtonBorderColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['borderColor'] ? style['global']['cancelButton']['borderColor'] : undefined;
        const cancelButtonLabelColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['labelColor'] ? style['global']['cancelButton']['labelColor'] : undefined;
        return (
            <HelpModal
                helpUrl={helpUrl}
                qrScanImage={qrScanImage}
                cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                cancelButtonBorderColor={cancelButtonBorderColor}
                cancelButtonLabelColor={cancelButtonLabelColor}
                onClose={closeHelp}
                metadata={getMetadata()}
            />
        );
    };

    const renderSurveyModal = () => {
        const surveyUrl = style && style['global'] && style['global']['surveyModal'] && style['global']['surveyModal']['surveyUrl'] ? style['global']['surveyModal']['surveyUrl'] : undefined;
        return (
            <SurveyModal
                surveyUrl={surveyUrl}
                onClose={closeSurvey}
                metadata={getMetadata()}
            />
        );
    };

    const renderShoppingCart = () => {
        const discountBackgroundColor = style && style['global'] && style['global']['prices'] && style['global']['prices']['discountBackgroundColor'] ? style['global']['prices']['discountBackgroundColor'] : undefined;
        const discountLabelColor = style && style['global'] && style['global']['prices'] && style['global']['prices']['discountLabelColor'] ? style['global']['prices']['discountLabelColor'] : undefined;
        const exclusivePriceLabelColor = style && style['global'] && style['global']['prices'] && style['global']['prices']['exclusivePriceLabelColor'] ? style['global']['prices']['exclusivePriceLabelColor'] : undefined;
        const exclusivePriceIcon = style && style['global'] && style['global']['prices'] && style['global']['prices']['exclusivePriceIcon'] ? style['global']['prices']['exclusivePriceIcon'] : undefined;
        const paymentCallToAction = style && style['global'] && style['global']['shoppingCart'] && style['global']['shoppingCart']['paymentCallToAction'] ? style['global']['shoppingCart']['paymentCallToAction'] : 'Pagar';
        const actionButtonBackgroundColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['backgroundColor'] ? style['global']['actionButton']['backgroundColor'] : undefined;
        const actionButtonBorderColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['borderColor'] ? style['global']['actionButton']['borderColor'] : undefined;
        const actionButtonLabelColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['labelColor'] ? style['global']['actionButton']['labelColor'] : undefined;
        const secondaryActionButtonBackgroundColor = style && style['global'] && style['global']['secondaryActionButton'] && style['global']['secondaryActionButton']['backgroundColor'] ? style['global']['secondaryActionButton']['backgroundColor'] : undefined;
        const secondaryActionButtonBorderColor = style && style['global'] && style['global']['secondaryActionButton'] && style['global']['secondaryActionButton']['borderColor'] ? style['global']['secondaryActionButton']['borderColor'] : undefined;
        const secondaryActionButtonLabelColor = style && style['global'] && style['global']['secondaryActionButton'] && style['global']['secondaryActionButton']['labelColor'] ? style['global']['secondaryActionButton']['labelColor'] : undefined;
        const cancelButtonBackgroundColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['backgroundColor'] ? style['global']['cancelButton']['backgroundColor'] : undefined;
        const cancelButtonBorderColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['borderColor'] ? style['global']['cancelButton']['borderColor'] : undefined;
        const cancelButtonLabelColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['labelColor'] ? style['global']['cancelButton']['labelColor'] : undefined;
        return (
            <ShoppingCart
                client={client}
                initialShoppingCart={shoppingCart}
                showBudgetPrintOption={showBudgetPrintOption()}
                discountBackgroundColor={discountBackgroundColor}
                discountLabelColor={discountLabelColor}
                exclusivePriceLabelColor={exclusivePriceLabelColor}
                exclusivePriceIcon={exclusivePriceIcon}
                paymentCallToAction={paymentCallToAction}
                actionButtonBackgroundColor={actionButtonBackgroundColor}
                actionButtonBorderColor={actionButtonBorderColor}
                actionButtonLabelColor={actionButtonLabelColor}
                secondaryActionButtonBackgroundColor={secondaryActionButtonBackgroundColor}
                secondaryActionButtonBorderColor={secondaryActionButtonBorderColor}
                secondaryActionButtonLabelColor={secondaryActionButtonLabelColor}
                cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                cancelButtonBorderColor={cancelButtonBorderColor}
                cancelButtonLabelColor={cancelButtonLabelColor}
                openSearch={openSearch}
                onPaymentAttempt={openCrossSellModal}
                onBudgetPrint={openBudgetPrintModal}
                onChange={updateShoppingCart}
                onClose={closeShoppingCart}
                metadata={getMetadata()}
            />
        );
    };

    const renderCrossSellModal = () => {
        const actionButtonBackgroundColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['backgroundColor'] ? style['global']['actionButton']['backgroundColor'] : undefined;
        const actionButtonBorderColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['borderColor'] ? style['global']['actionButton']['borderColor'] : undefined;
        const actionButtonLabelColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['labelColor'] ? style['global']['actionButton']['labelColor'] : undefined;
        const cancelButtonBackgroundColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['backgroundColor'] ? style['global']['cancelButton']['backgroundColor'] : undefined;
        const cancelButtonBorderColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['borderColor'] ? style['global']['cancelButton']['borderColor'] : undefined;
        const cancelButtonLabelColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['labelColor'] ? style['global']['cancelButton']['labelColor'] : undefined;
        const clientName = style && style['global'] && style['global']['clientName'] ? style['global']['clientName'] : undefined;
        const _3dIcon = style && style['category'] && style['category']['3dIcon'] ? style['category']['3dIcon'] : undefined;
        const featureIcon = style && style['category'] && style['category']['featureIcon'] ? style['category']['featureIcon'] : undefined;
        const discountBackgroundColor = style && style['global'] && style['global']['prices'] && style['global']['prices']['discountBackgroundColor'] ? style['global']['prices']['discountBackgroundColor'] : undefined;
        const discountLabelColor = style && style['global'] && style['global']['prices'] && style['global']['prices']['discountLabelColor'] ? style['global']['prices']['discountLabelColor'] : undefined;
        const exclusivePriceLabelColor = style && style['global'] && style['global']['prices'] && style['global']['prices']['exclusivePriceLabelColor'] ? style['global']['prices']['exclusivePriceLabelColor'] : undefined;
        const exclusivePriceIcon = style && style['global'] && style['global']['prices'] && style['global']['prices']['exclusivePriceIcon'] ? style['global']['prices']['exclusivePriceIcon'] : undefined;
        const offerBackgroundColor = style && style['global'] && style['global']['offers'] && style['global']['offers']['backgroundColor'] ? style['global']['offers']['backgroundColor'] : undefined;
        const offerLabelColor      = style && style['global'] && style['global']['offers'] && style['global']['offers']['labelColor'] ? style['global']['offers']['labelColor'] : undefined;
        const freeDeliveryIcon = style && style['global'] && style['global']['tags'] && style['global']['tags']['freeDelivery'] && style['global']['tags']['freeDelivery']['icon'] ? style['global']['tags']['freeDelivery']['icon'] : undefined;
        const freeDeliveryLabelColor = style && style['global'] && style['global']['tags'] && style['global']['tags']['freeDelivery'] && style['global']['tags']['freeDelivery']['labelColor'] ? style['global']['tags']['freeDelivery']['labelColor'] : undefined;
        const freeDeliveryBackgroundColor = style && style['global'] && style['global']['tags'] && style['global']['tags']['freeDelivery'] && style['global']['tags']['freeDelivery']['backgroundColor'] ? style['global']['tags']['freeDelivery']['backgroundColor'] : undefined;
        const deliveryIcon = style && style['global'] && style['global']['tags'] && style['global']['tags']['delivery'] && style['global']['tags']['delivery']['icon'] ? style['global']['tags']['delivery']['icon'] : undefined;
        const deliveryLabelColor = style && style['global'] && style['global']['tags'] && style['global']['tags']['delivery'] && style['global']['tags']['delivery']['labelColor'] ? style['global']['tags']['delivery']['labelColor'] : undefined;
        const deliveryBackgroundColor = style && style['global'] && style['global']['tags'] && style['global']['tags']['delivery'] && style['global']['tags']['delivery']['backgroundColor'] ? style['global']['tags']['delivery']['backgroundColor'] : undefined;
        const pickupIcon = style && style['global'] && style['global']['tags'] && style['global']['tags']['pickup'] && style['global']['tags']['pickup']['icon'] ? style['global']['tags']['pickup']['icon'] : undefined;
        const pickupLabelColor = style && style['global'] && style['global']['tags'] && style['global']['tags']['pickup'] && style['global']['tags']['pickup']['labelColor'] ? style['global']['tags']['pickup']['labelColor'] : undefined;
        const pickupBackgroundColor = style && style['global'] && style['global']['tags'] && style['global']['tags']['pickup'] && style['global']['tags']['pickup']['backgroundColor'] ? style['global']['tags']['pickup']['backgroundColor'] : undefined;
        const sellerLabelColor = style && style['global'] && style['global']['seller'] && style['global']['seller']['labelColor'] ? style['global']['seller']['labelColor'] : undefined;
        const mainSellerIcon = style && style['global'] && style['global']['seller'] && style['global']['seller']['mainIcon'] ? style['global']['seller']['mainIcon'] : undefined;
        return (
            <CrossSellModal
                catalog={catalog}
                client={client}
                shoppingCart={shoppingCart}
                actionButtonBackgroundColor={actionButtonBackgroundColor}
                actionButtonBorderColor={actionButtonBorderColor}
                actionButtonLabelColor={actionButtonLabelColor}
                cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                cancelButtonBorderColor={cancelButtonBorderColor}
                cancelButtonLabelColor={cancelButtonLabelColor}
                clientName={clientName}
                _3dIcon={_3dIcon}
                featureIcon={featureIcon}
                discountBackgroundColor={discountBackgroundColor}
                discountLabelColor={discountLabelColor}
                exclusivePriceLabelColor={exclusivePriceLabelColor}
                exclusivePriceIcon={exclusivePriceIcon}
                offerBackgroundColor={offerBackgroundColor}
                offerLabelColor={offerLabelColor}
                freeDeliveryIcon={freeDeliveryIcon}
                freeDeliveryLabelColor={freeDeliveryLabelColor}
                freeDeliveryBackgroundColor={freeDeliveryBackgroundColor}
                deliveryIcon={deliveryIcon}
                deliveryLabelColor={deliveryLabelColor}
                deliveryBackgroundColor={deliveryBackgroundColor}
                pickupIcon={pickupIcon}
                pickupLabelColor={pickupLabelColor}
                pickupBackgroundColor={pickupBackgroundColor}
                sellerLabelColor={sellerLabelColor}
                mainSellerIcon={mainSellerIcon}
                onCheckout={openPaymentModal}
                onClose={closeCrossSellModal}
                metadata={getMetadata()}
            />
        );
    };

    const renderPaymentModal = () => {
        const mainClientLogo = style && style['global'] ? style['global']['mainClientLogo'] : undefined;
        const kioskOptionImage = style && style['global'] && style['global']['checkout'] && style['global']['checkout']['kioskOptionImage'] ? style['global']['checkout']['kioskOptionImage'] : undefined;
        const smartphoneOptionImage = style && style['global'] && style['global']['checkout'] && style['global']['checkout']['smartphoneOptionImage'] ? style['global']['checkout']['smartphoneOptionImage'] : undefined;
        const smartphoneOptionQrScanImage = style && style['global'] && style['global']['checkout'] && style['global']['checkout']['smartphoneOptionQrScanImage'] ? style['global']['checkout']['smartphoneOptionQrScanImage'] : undefined;
        const personOptionImage = style && style['global'] && style['global']['checkout'] && style['global']['checkout']['personOptionImage'] ? style['global']['checkout']['personOptionImage'] : undefined;
        const companyOptionImage = style && style['global'] && style['global']['checkout'] && style['global']['checkout']['companyOptionImage'] ? style['global']['checkout']['companyOptionImage'] : undefined;
        const cardOptionImage = style && style['global'] && style['global']['checkout'] && style['global']['checkout']['cardOptionImage'] ? style['global']['checkout']['cardOptionImage'] : undefined;
        const otherPaymentOptionImage = style && style['global'] && style['global']['checkout'] && style['global']['checkout']['otherPaymentOptionImage'] ? style['global']['checkout']['otherPaymentOptionImage'] : undefined;
        const billOptionImage = style && style['global'] && style['global']['checkout'] && style['global']['checkout']['billOptionImage'] ? style['global']['checkout']['billOptionImage'] : undefined;
        const invoiceOptionImage = style && style['global'] && style['global']['checkout'] && style['global']['checkout']['invoiceOptionImage'] ? style['global']['checkout']['invoiceOptionImage'] : undefined;
        const printMessageImage1 = style && style['global'] && style['global']['checkout'] && style['global']['checkout']['printMessageImage1'] ? style['global']['checkout']['printMessageImage1'] : undefined;
        const printMessageImage2 = style && style['global'] && style['global']['checkout'] && style['global']['checkout']['printMessageImage2'] ? style['global']['checkout']['printMessageImage2'] : undefined;
        const actionButtonBackgroundColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['backgroundColor'] ? style['global']['actionButton']['backgroundColor'] : undefined;
        const actionButtonBorderColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['borderColor'] ? style['global']['actionButton']['borderColor'] : undefined;
        const actionButtonLabelColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['labelColor'] ? style['global']['actionButton']['labelColor'] : undefined;
        const cancelButtonBackgroundColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['backgroundColor'] ? style['global']['cancelButton']['backgroundColor'] : undefined;
        const cancelButtonBorderColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['borderColor'] ? style['global']['cancelButton']['borderColor'] : undefined;
        const cancelButtonLabelColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['labelColor'] ? style['global']['cancelButton']['labelColor'] : undefined;
        const backButtonBackgroundColor = style && style['global'] && style['global']['secondaryActionButton'] && style['global']['secondaryActionButton']['backgroundColor'] ? style['global']['secondaryActionButton']['backgroundColor'] : undefined;
        const backButtonBorderColor = style && style['global'] && style['global']['secondaryActionButton'] && style['global']['secondaryActionButton']['borderColor'] ? style['global']['secondaryActionButton']['borderColor'] : undefined;
        const backButtonLabelColor = style && style['global'] && style['global']['secondaryActionButton'] && style['global']['secondaryActionButton']['labelColor'] ? style['global']['secondaryActionButton']['labelColor'] : undefined;
        const customInputOutline = style && style['global'] && style['global']['customInput'] && style['global']['customInput']['outline'] ? style['global']['customInput']['outline'] : undefined;
        const customInputClearButtonBackgroundColor = style && style['global'] && style['global']['customInput'] && style['global']['customInput']['clearButtonBackgroundColor'] ? style['global']['customInput']['clearButtonBackgroundColor'] : undefined;
        const customInputClearButtonLabelColor = style && style['global'] && style['global']['customInput'] && style['global']['customInput']['clearButtonLabelColor'] ? style['global']['customInput']['clearButtonLabelColor'] : undefined;
        return (
            <PaymentModal
                client={client}
                store={store}
                shoppingCart={shoppingCart}
                paymentMode={paymentMode}
                mainClientLogo={mainClientLogo}
                kioskOptionImage={kioskOptionImage}
                smartphoneOptionImage={smartphoneOptionImage}
                smartphoneOptionQrScanImage={smartphoneOptionQrScanImage}
                personOptionImage={personOptionImage}
                companyOptionImage={companyOptionImage}
                cardOptionImage={cardOptionImage}
                otherPaymentOptionImage={otherPaymentOptionImage}
                billOptionImage={billOptionImage}
                invoiceOptionImage={invoiceOptionImage}
                printMessageImage1={printMessageImage1}
                printMessageImage2={printMessageImage2}
                actionButtonBackgroundColor={actionButtonBackgroundColor}
                actionButtonBorderColor={actionButtonBorderColor}
                actionButtonLabelColor={actionButtonLabelColor}
                cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                cancelButtonBorderColor={cancelButtonBorderColor}
                cancelButtonLabelColor={cancelButtonLabelColor}
                backButtonBackgroundColor={backButtonBackgroundColor}
                backButtonBorderColor={backButtonBorderColor}
                backButtonLabelColor={backButtonLabelColor}
                customInputOutline={customInputOutline}
                customInputClearButtonBackgroundColor={customInputClearButtonBackgroundColor}
                customInputClearButtonLabelColor={customInputClearButtonLabelColor}
                onClose={closePaymentModal}
                metadata={getMetadata()}
            />
        );
    };

    const renderInactivityModal = () => {
        const timerLabelColor = style && style['global'] && style['global']['inactivityAlert'] && style['global']['inactivityAlert']['timerLabelColor'] ? style['global']['inactivityAlert']['timerLabelColor'] : undefined;
        const actionButtonBackgroundColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['backgroundColor'] ? style['global']['actionButton']['backgroundColor'] : undefined;
        const actionButtonBorderColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['borderColor'] ? style['global']['actionButton']['borderColor'] : undefined;
        const actionButtonLabelColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['labelColor'] ? style['global']['actionButton']['labelColor'] : undefined;
        const cancelButtonBackgroundColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['backgroundColor'] ? style['global']['cancelButton']['backgroundColor'] : undefined;
        const cancelButtonBorderColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['borderColor'] ? style['global']['cancelButton']['borderColor'] : undefined;
        const cancelButtonLabelColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['labelColor'] ? style['global']['cancelButton']['labelColor'] : undefined;
        return (
            <InactivityModal
                remainingTimeUntilReset={remainingTimeUntilReset}
                timerLabelColor={timerLabelColor}
                actionButtonBackgroundColor={actionButtonBackgroundColor}
                actionButtonBorderColor={actionButtonBorderColor}
                actionButtonLabelColor={actionButtonLabelColor}
                cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                cancelButtonBorderColor={cancelButtonBorderColor}
                cancelButtonLabelColor={cancelButtonLabelColor}
                onClose={closeInactivityModal}
                onReset={resetKiosk}
                metadata={getMetadata()}
            />
        );
    };

    const renderChildren = () => {
        return Children.map(children, child => {
            // noinspection JSCheckFunctionSignatures
            if (React.isValidElement(child)) {
                return React.cloneElement(child, {
                    client,
                    instance,
                    catalog,
                    session,
                    store,
                    style,
                    zoomLevel,
                    openShoppingCart,
                    closeShoppingCart,
                    addItemToCart,
                    removeItemFromCart,
                    inSmallMode: inSmallMode(),
                    accessibilityMode,
                    showConfigModal,
                    showSearch,
                    showHelp,
                    showSurvey,
                    showShoppingCart,
                    showCrossSellModal,
                    showPaymentModal,
                    showInactivityModal,
                    showPromotionalVideo,
                    viewRef,
                    onAccessibilityButtonClick,
                    openInactivityModal,
                    closeInactivityModal,
                    openActionBar,
                    closeActionBar,
                    openActionBarMobile,
                    closeActionBarMobile,
                    onConfigClear,
                    algoliaInsights
                });
            }
            return child;
        });
    };

    const accessibilityButtonIcon = style && style['global'] && style['global']['accessibilityButton'] && style['global']['accessibilityButton']['icon'] ? style['global']['accessibilityButton']['icon'] : undefined;
    const accessibilityButtonBackgroundColor = style && style['global'] && style['global']['accessibilityButton'] && style['global']['accessibilityButton']['backgroundColor'] ? style['global']['accessibilityButton']['backgroundColor'] : undefined;
    const zoomLevelPercent = Math.round(currentZoomLevel * 100);

    return (
        <div ref={viewRef} className='view' style={viewStyle()}>
            {!showConfigModal && (
                <div className='view-config-button' onMouseDown={handleConfigButtonPress} onTouchStart={handleConfigButtonPress} onMouseUp={handleConfigButtonRelease} onTouchEnd={handleConfigButtonRelease} onMouseLeave={handleConfigButtonRelease} style={configButtonStyle()}>
                    <img className='view-config-button-icon' src={`${configIcon}`} alt='Config' draggable={false}/>
                </div>
            )}
            <div className='view-config-overlay' style={configOverlayStyle()}>
                {!showConfigModal && <p className='view-config-overlay-timer'>Abriendo configuración en {CONFIG_PRESS_TIME - elapsedConfigTime} segundo(s)...</p>}
                {showConfigModal && (
                    <div className='view-config-panel'>
                        <div className='view-config-panel-close-button' onClick={handleConfigClose}>
                            <span className='view-config-panel-close-button-icon'>x</span>
                        </div>
                        <h2 className='view-config-panel-title'>Configuración</h2>
                        <div className='view-config-panel-option'>
                            <label className='view-config-panel-option-label'>Zoom: {zoomLevelPercent}%</label>
                            <Slider
                                initialValue={zoomLevelPercent}
                                min={30}
                                max={200}
                                step={1}
                                highlightColor="#888888"
                                onChange={handleCurrentZoomLevelChange}
                                onRelease={handleCurrentZoomLevelRelease}
                            />
                        </div>
                        <div className='view-config-panel-buttons'>
                            <Button label='Reiniciar Kiosco' labelColor='#FFFFFF' backgroundColor='#CD5C5C' borderColor='#CD5C5C' boxShadow='unset' onClick={handleConfigClear}/>
                        </div>
                    </div>
                )}
            </div>
            {!inSmallMode() && (
                <div className='view-top-spacer' style={topSpacerStyle()}>
                </div>
            )}
            {renderChildren()}
            {!inSmallMode() && (
                <div className='view-bottom-spacer' style={bottomSpacerStyle()}>
                </div>
            )}
            {showActionBar && renderActionBar()}
            {showAccessibilityButton && !inSmallMode() && renderAccessibilityButton()}
            {showSearch && !showInactivityModal && renderSearchModal()}
            {hasHelpUrl && showHelp && renderHelpModal()}
            {hasSurveyUrl && showSurvey && renderSurveyModal()}
            {showShoppingCart && renderShoppingCart()}
            {showCrossSellModal && !showInactivityModal && renderCrossSellModal()}
            {showPaymentModal && !showInactivityModal && renderPaymentModal()}
            {showInactivityModal && renderInactivityModal()}
        </div>
    );
}

export default View;