import WebServices from './WebServices';
import {TRANSBANK_POS_LAST_SALE_URL, TRANSBANK_POS_POLL_URL, TRANSBANK_POS_SALE_URL} from '../config/App';

export default class TransbankPOS {
    static status = () => {
        return fetch(TRANSBANK_POS_POLL_URL, {method: 'POST', mode: 'cors', cache: 'no-cache', headers: {'Content-Type': 'application/json'}})
            .then(response => WebServices.onResponse(response))
            .then(response => {
                if (response) return response.status;
                else return false;
            });
    };

    // TODO: Remove forced timeout.
    static startSale = (amount, ticket, print, timeout, signal) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                const body = {Amount: amount, Ticket: ticket, Print: print ? 1 : 0};
                return fetch(TRANSBANK_POS_SALE_URL, {method: 'POST', mode: 'cors', cache: 'no-cache', headers: {'Content-Type': 'application/json'}, signal: signal, body: JSON.stringify(body)})
                    .then(response => WebServices.onResponse(response))
                    .then(response => {
                        if (response) resolve(response);
                        else resolve(undefined);
                    });
            }, 1000 * timeout);
        });
    };

    // noinspection JSUnusedGlobalSymbols
    static getLastSale = () => {
        return fetch(TRANSBANK_POS_LAST_SALE_URL, {method: 'GET', mode: 'cors', cache: 'no-cache', headers: {'Content-Type': 'application/json'}})
            .then(response => WebServices.onResponse(response))
            .then(response => {
                if (response) return response;
                else return undefined;
            });
    };
}