import React, {createRef} from 'react';
import PropTypes from "prop-types";
import Signup from '../Signup/Signup';
import PaymentOptionSelection from '../../PaymentSteps/PaymentOptionSelection/PaymentOptionSelection';
import PaymentMessage from '../../PaymentSteps/PaymentMessage/PaymentMessage';
import Budget from '../../PaymentDocuments/Budget/Budget';
import WaitingPayment from '../../PaymentSteps/WaitingPayment/WaitingPayment';
import DataError from "../../../DataError/DataError";
import Activity from '../../../../helpers/Activity';
import Pin from "../../../../helpers/Pin";
import Printer from '../../../../helpers/Printer';
import TransbankPOS from '../../../../helpers/TransbankPOS';
import Debug from '../../../../helpers/Debug';
import defaultCardOptionImage from './images/card-option.png';
import defaultOtherPaymentOptionImage from './images/other-payment-option.png';
import defaultBillOptionImage from './images/bill-option.png';
import defaultInvoiceOptionImage from './images/invoice-option.png';
import defaultPrintMessageImage1 from './images/message-step-one.png';
import defaultPrintMessageImage2 from './images/message-step-two.png';
import defaultSuccessImage from './images/success.png';
import './IntegratedPOS.css';

// TODO: Save transaction id in shopping cart.
export default class IntegratedPOS extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 'signup',
            registerData: undefined,
            paymentMode: undefined,
            receiptType: undefined,
            isCreatingTicket: false,
            ticketCreated: false,
            ticketPrinted: false,
            ticketPrintError: true,
            maxPaymentTime: 60 * 3, // 3 Minutes.
            elapsedPaymentTime: 0,
            paymentErrorMessage: undefined
        };
        this._isMounted = true;
        this._containerRef = createRef();
        this._ticketRef = createRef();
        this._paymentInterval = undefined;
        this._paymentController = undefined;
    };

    changeState = (newState, callback) => {
        if (this._isMounted) {
            this.setState(newState, () => {
                if (callback) callback();
            });
        }
    };

    componentDidMount() {
        this._isMounted = true;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.state.step === 'waiting-payment' ? this.props.preventClosing() : this.props.allowClosing();
        if (this.state.step === 'waiting-payment' && prevState.step !== this.state.step) this.sendPaymentSignal();
    };

    componentWillUnmount() {
        this._isMounted = false;
    };

    isPerson = () => {
        const {registerData} = this.state;
        return registerData && registerData.client && registerData.client.entity && registerData.client.entity === 'Person';
    };

    isCompany = () => {
        const {registerData} = this.state;
        return registerData && registerData.client && registerData.client.entity && registerData.client.entity === 'Company';
    };

    handleClose = (closeType) => {
        Activity.log(this.props.metadata, 'checkout', closeType, 'close', {type: 'integrated-pos'});
        if (this.props.onClose) this.props.onClose();
    };

    handleBack = () => {
        let newState = {};
        switch (this.state.step) {
            case 'payment-mode-selection':
                newState.step = 'signup';
                break;
            case 'receipt-type-selection':
                if (this.isCompany()) newState.paymentMode = undefined;
                newState.step = 'payment-mode-selection';
                break;
            default: // Do not allow back navigation for the following states: 'signup', 'other-payment-ticket-print', 'waiting-payment', 'payment-error' and 'payment-success'.
                break;
        }
        Activity.log(this.props.metadata, 'checkout', 'back-button', 'back', {type: 'integrated-pos'});
        this.changeState(newState);
    };

    onRegisterFinished = (newRegisterData) => {
        this.changeState({registerData: newRegisterData, step: 'payment-mode-selection'});
    };

    onPaymentModeSelected = (newPaymentMode) => {
        if (['card', 'other-payment'].includes(newPaymentMode)) {
            let newState = {paymentMode: newPaymentMode};
            if (newPaymentMode === 'card') {
                if (this.isPerson()) {
                    newState.receiptType = 'bill';
                    newState.step = 'waiting-payment';
                }
                else if (this.isCompany()) newState.step = 'receipt-type-selection';
                else newState.step = 'receipt-type-selection';
            } else {
                newState.step = 'other-payment-ticket-print';
                newState.isCreatingTicket = true;
                newState.ticketCreated = false;
            }
            Activity.log(this.props.metadata, 'checkout', this.state.step, `selected-${newPaymentMode}-option`, {
                type: 'integrated-pos',
                paymentMode: newPaymentMode,
                entity: this.state.registerData.client.entity,
                shoppingCart: this.props.shoppingCart.toActivityLog()
            });
            this.changeState(newState);
        }
    };

    onReceiptTypeSelected = (receiptType) => {
        Activity.log(this.props.metadata, 'checkout', this.state.step, `selected-${receiptType}-option`, {
            type: 'integrated-pos',
            paymentMode: this.state.paymentMode,
            receiptType: receiptType,
            entity: this.state.registerData.client.entity,
            shoppingCart: this.props.shoppingCart.toActivityLog()
        });
        this.changeState({receiptType: receiptType, step: 'waiting-payment'});
    };

    onDocumentLoaded = () => {
        setTimeout(() => {
            this.changeState({isCreatingTicket: false, ticketCreated: true});
        }, 2000);
    };

    printTicket = () => {
        Activity.log(this.props.metadata, 'checkout', this.state.step, 'print', {
            type: 'integrated-pos',
            paymentMode: this.state.paymentMode,
            entity: this.state.registerData.client.entity,
            shoppingCart: this.props.shoppingCart.toActivityLog()
        });
        this.changeState({ticketPrinted: false, ticketPrintError: false}, () => {
            return Printer.print(this._containerRef, this._ticketRef)
                .then((status) => {
                    this.changeState({ticketPrinted: true, ticketPrintError: !status});
                })
                .then(() => {
                    setTimeout(() => {
                        this.changeState({ticketPrinted: false, ticketPrintError: false});
                    }, 1000 * 10);
                });
        });
    };

    sendPaymentSignal = () => {
        this._paymentController = new AbortController();
        const signal = this._paymentController.signal;
        TransbankPOS.status()
            .then((response) => {
                if (response) {
                    this.startPaymentInterval();
                    return TransbankPOS.startSale(this.props.shoppingCart.getTotal(), this.state.registerData.budgetId, true, 3, signal); // We are not using exclusive total, because we do not have a way to know if the user has a credit/debit card from the client.
                } else throw new Error('payment-server-offline');
            })
            .then((response) => {
                if (response) {
                    Debug.printToLog('info', `Payment server responded with code: ${response['responseCode']}`);
                    switch (response['responseCode']) {
                        case 0: // Aprobado
                            this.onPaymentReceived();
                            break;
                        case 1: // Rechazado
                            throw new Error('payment-rejected');
                        case 2: // Autorizador no responde
                            throw new Error('authorizer-no-response');
                        case 5: // No existe transacción para anular
                            throw new Error('no-transaction-to-cancel');
                        case 6: // Tarjeta no soportada
                            throw new Error('card-not-supported');
                        case 7: // Transacción cancelada
                            throw new Error('transaction-canceled');
                        case 9: // Error lectura tarjeta
                            throw new Error('card-reading-error');
                        case 10: // Monto menor al mínimo permitido
                            throw new Error('amount-less-than-minimum');
                        case 11: // No existe venta
                            throw new Error('no-sale');
                        case 12: // Transacción no soportada
                            throw new Error('transaction-not-supported');
                        case 78: // Modo Multicomercio no activo
                            throw new Error('multi-commerce-mode-not-active');
                        case 79: // Modo Venta Normal no Activo
                            throw new Error('normal-sale-mode-not-active');
                        case 81: // Solicitando ingreso de clave
                            throw new Error('requesting-key-entry');
                        case 82: // Enviando transacción al autorizador
                            throw new Error('sending-to-authorizer');
                        case 83: // Selección menú crédito/Redcompra
                            throw new Error('credit-debit-selection-menu');
                        case 84: // Opere tarjeta
                            throw new Error('operate-card');
                        case 85: // Selección de cuotas
                            throw new Error('select-installments');
                        case 86: // Ingreso de cuotas
                            throw new Error('installments-entry');
                        case 88: // Aceptar consulta cuotas
                            throw new Error('accept-installments-query');
                        case 87: // Confirmación de cuotas
                            throw new Error('installments-confirmation');
                        case 90: // Inicialización exitosa
                            throw new Error('initialization-success');
                        case 91: // Inicialización fallida
                            throw new Error('initialization-failed');
                        case 93: // Consultando cuota al autorizador
                            throw new Error('querying-installments');
                        default: // Otros
                            throw new Error('other-error');
                    }
                } else throw new Error('no-response');
            })
            .catch((error) => {
                let userMessage;
                const errorMessage = error && error.message ? error.message : undefined;
                Debug.printToLog('error', `Transaction failed with error: ${errorMessage}`);
                // Errors that are not included will be treated as unknown errors (not every error requires an explanation).
                switch (errorMessage) {
                    case 'payment-server-offline':
                        userMessage = 'No se ha podido establecer conexión con el servidor de pago';
                        break;
                    case 'no-response':
                        userMessage = 'El servidor de pago no ha respondido';
                        break;
                    case 'payment-rejected':
                        userMessage = 'El pago ha sido rechazado';
                        break;
                    case 'authorizer-no-response':
                        userMessage = 'El autorizador no responde';
                        break;
                    case 'card-not-supported':
                        userMessage = 'La tarjeta usada no es soportada';
                        break;
                    case 'transaction-canceled':
                        userMessage = 'La transacción ha sido cancelada';
                        break;
                    case 'card-reading-error':
                        userMessage = 'Se ha producido un error al leer la tarjeta';
                        break;
                    case 'amount-less-than-minimum':
                        userMessage = 'El monto de la transacción es menor al mínimo permitido';
                        break;
                    default:
                        userMessage = 'Se ha producido un error al realizar el pago';
                        break;
                }
                this.onPaymentError(userMessage);
            });
    };

    abortPayment = () => {
        if (this._paymentController) this._paymentController.abort();
    };

    startPaymentInterval = () => {
        this.resetPaymentInterval();
        this._paymentInterval = setInterval(() => {
            const elapsedPaymentTime = this.state.elapsedPaymentTime + 1;
            if (elapsedPaymentTime >= this.state.maxPaymentTime) {
                this.abortPayment();
                this.onPaymentExpired();
            }
            this.changeState({elapsedPaymentTime: elapsedPaymentTime});
        }, 1000);
    };

    stopPaymentInterval = () => {
        if (this._paymentInterval) clearInterval(this._paymentInterval);
    };

    resetPaymentInterval = () => {
        this.changeState({elapsedPaymentTime: 0});
    };

    onPaymentExpired = () => {
        this._paymentController = undefined;
        this.stopPaymentInterval();
        this.changeState({step: 'payment-expired'}, () => this.sendPaymentMetric('cancel'));
    };

    onPaymentError = (message) => {
        this._paymentController = undefined;
        this.stopPaymentInterval();
        this.changeState({step: 'payment-error', paymentErrorMessage: message}, () => this.sendPaymentMetric('cancel'));
    };

    onPaymentReceived = () => {
        this._paymentController = undefined;
        this.stopPaymentInterval();
        this.changeState({step: 'payment-success'}, () => this.sendPaymentMetric('continue'));
    };

    retryPayment = () => {
        this.sendPaymentMetric('retry'); // Metric is sent before changing the state, so the step says payment-error.
        this.changeState({step: 'waiting-payment', paymentErrorMessage: undefined});
    };

    sendPaymentMetric = (action) => {
        Activity.log(this.props.metadata, 'checkout', this.state.step, action, {
            type: 'integrated-pos',
            paymentMode: this.state.paymentMode,
            receiptType: this.state.receiptType,
            entity: this.state.registerData.client.entity,
            shoppingCart: this.props.shoppingCart.toActivityLog()
        });
    };

    renderStep = () => {
        switch (this.state.step) {
            case 'signup':
                return this.renderSignup();
            case 'payment-mode-selection':
                return this.renderPaymentModeSelection();
            case 'receipt-type-selection':
                return this.renderCardReceiptTypeSelection();
            case 'other-payment-ticket-print':
                return this.renderOtherPaymentTicketPrint();
            case 'waiting-payment':
                return this.renderWaitingPayment();
            case 'payment-expired':
                return this.renderPaymentExpired();
            case 'payment-error':
                return this.renderPaymentError();
            case 'payment-success':
                return this.renderPaymentSuccess();
            default:
                break;
        }
    };

    renderSignup = () => {
        return (
            <Signup
                flow='integrated-pos'
                initialData={this.state.registerData && this.state.registerData.client ? this.state.registerData.client : undefined}
                store={undefined}
                shoppingCart={this.props.shoppingCart}
                allowBudgetPrint={false}
                mainClientLogo={this.props.mainClientLogo}
                personOptionImage={this.props.personOptionImage}
                companyOptionImage={this.props.companyOptionImage}
                printMessageImage1={undefined}
                printMessageImage2={undefined}
                actionButtonBackgroundColor={this.props.actionButtonBackgroundColor}
                actionButtonBorderColor={this.props.actionButtonBorderColor}
                actionButtonLabelColor={this.props.actionButtonLabelColor}
                cancelButtonBackgroundColor={this.props.cancelButtonBackgroundColor}
                cancelButtonBorderColor={this.props.cancelButtonBorderColor}
                cancelButtonLabelColor={this.props.cancelButtonLabelColor}
                backButtonBackgroundColor={this.props.backButtonBackgroundColor}
                backButtonBorderColor={this.props.backButtonBorderColor}
                backButtonLabelColor={this.props.backButtonLabelColor}
                customInputOutline={this.props.customInputOutline}
                customInputClearButtonBackgroundColor={this.props.customInputClearButtonBackgroundColor}
                customInputClearButtonLabelColor={this.props.customInputClearButtonLabelColor}
                inSmallMode={this.props.inSmallMode}
                onClose={this.props.onClose}
                onRegisterFinished={this.onRegisterFinished}
                metadata={this.props.metadata}
            />
        );
    };

    renderPaymentModeSelection = () => {
        const options = [
            {
                type: 'card',
                icon: this.props.cardOptionImage || defaultCardOptionImage,
                label1: 'Paga aquí',
                label2: 'con tu tarjeta de crédito o débito',
                boldLabel1: true,
                boldLabel2: false,
                labelLineBreak: true,
                waitingMode: false,
                waitingModeIcon: undefined,
                waitingModeLabel: undefined,
                onClick: this.onPaymentModeSelected
            },
            {
                type: 'other-payment',
                icon: this.props.otherPaymentOptionImage || defaultOtherPaymentOptionImage,
                label1: 'Paga en caja',
                label2: 'con otros medios de pago',
                boldLabel1: true,
                boldLabel2: false,
                labelLineBreak: true,
                waitingMode: false,
                waitingModeIcon: undefined,
                waitingModeLabel: undefined,
                onClick: this.onPaymentModeSelected
            }
        ];
        return (
            <PaymentOptionSelection
                title1='Paga de productos'
                title2='Selecciona una de las siguientes opciones:'
                titleLineBreak={true}
                options={options}
                cancelButtonBackgroundColor={this.props.cancelButtonBackgroundColor}
                cancelButtonBorderColor={this.props.cancelButtonBorderColor}
                cancelButtonLabelColor={this.props.cancelButtonLabelColor}
                backButtonBackgroundColor={this.props.backButtonBackgroundColor}
                backButtonBorderColor={this.props.backButtonBorderColor}
                backButtonLabelColor={this.props.backButtonLabelColor}
                onClose={() => this.handleClose('custom-close-button')}
                onBack={this.handleBack}
            />
        );
    };

    renderCardReceiptTypeSelection = () => {
        const options = [
            {
                type: 'bill',
                icon: this.props.billOptionImage || defaultBillOptionImage,
                label1: 'Boleta',
                label2: undefined,
                boldLabel1: true,
                boldLabel2: false,
                labelLineBreak: true,
                waitingMode: false,
                waitingModeIcon: undefined,
                waitingModeLabel: undefined,
                onClick: this.onReceiptTypeSelected
            },
            {
                type: 'invoice',
                icon: this.props.invoiceOptionImage || defaultInvoiceOptionImage,
                label1: 'Factura',
                label2: undefined,
                boldLabel1: true,
                boldLabel2: false,
                labelLineBreak: true,
                waitingMode: false,
                waitingModeIcon: undefined,
                waitingModeLabel: undefined,
                onClick: this.onReceiptTypeSelected
            }
        ];
        return (
            <PaymentOptionSelection
                title1='¿Qué tipo de comprobante quieres?'
                title2='Selecciona una de las siguientes opciones:'
                titleLineBreak={true}
                options={options}
                cancelButtonBackgroundColor={this.props.cancelButtonBackgroundColor}
                cancelButtonBorderColor={this.props.cancelButtonBorderColor}
                cancelButtonLabelColor={this.props.cancelButtonLabelColor}
                backButtonBackgroundColor={this.props.backButtonBackgroundColor}
                backButtonBorderColor={this.props.backButtonBorderColor}
                backButtonLabelColor={this.props.backButtonLabelColor}
                onClose={() => this.handleClose('custom-close-button')}
                onBack={this.handleBack}
            />
        );
    };

    renderOtherPaymentTicketPrint = () => {
        return (
            <PaymentMessage
                isLoading={this.state.isCreatingTicket}
                loaded={this.state.ticketCreated}
                showPreview={true}
                preview={this.renderDocumentPreview()}
                previewSize={Printer.getDocumentSize()}
                title1='Imprime tu ticket'
                title2='y continúa con tu compra en caja'
                loadingMessage='Preparando ticket...'
                step1Message1='Imprime y retira el ticket'
                step1Message2=' impreso por el Kiosco'
                step1Image={this.props.printMessageImage1 || defaultPrintMessageImage1}
                step2Message1='Acércate a caja'
                step2Message2=' para pagar por tus productos'
                step2Image={this.props.printMessageImage2 || defaultPrintMessageImage2}
                errorMessage='Se ha producido un error al imprimir tu ticket'
                actionLabel='Imprimir'
                actionButtonBackgroundColor={this.props.actionButtonBackgroundColor}
                actionButtonBorderColor={this.props.actionButtonBorderColor}
                actionButtonLabelColor={this.props.actionButtonLabelColor}
                cancelButtonBackgroundColor={this.props.cancelButtonBackgroundColor}
                cancelButtonBorderColor={this.props.cancelButtonBorderColor}
                cancelButtonLabelColor={this.props.cancelButtonLabelColor}
                onClose={() => this.handleClose('custom-close-button')}
                onSubmit={this.printTicket}
                submitted={this.state.ticketPrinted}
                submitSuccess={!this.state.ticketPrintError}
                submitSuccessMessage='Tu ticket se está imprimiendo...'
                submitErrorMessage='Se ha producido un error al imprimir tu ticket'
            />
        );
    };

    renderDocumentPreview = () => {
        return (
            <Budget
                documentRef={this._ticketRef}
                title='PRESUPUESTO ELECTRÓNICO'
                id={this.state.registerData.budgetId}
                idPrefix='N°'
                image={this.state.registerData.company.companyLogo}
                issuerName={this.state.registerData.company.companyName}
                issuerAddressLabel='Casa Matriz'
                issuerAddress={this.state.registerData.company.hqAddress}
                issuerActivityLabel='Giro'
                issuerActivity={this.state.registerData.company.hqActivity}
                issuerBranchLabel='Sucursal'
                issuerBranch={this.props.store.address}
                receiverName={this.state.registerData.client.name}
                receiverPinLabel='RUT'
                receiverPin={Pin.format(this.state.registerData.client.pin)}
                receiverActivityLabel='Giro'
                receiverActivity={this.state.registerData.client.activityName}
                receiverAddressLabel='Dirección'
                receiverAddress={this.state.registerData.client.street}
                receiverDistrictLabel='Comuna'
                receiverDistrict={this.state.registerData.client.district}
                receiverCityLabel='Ciudad'
                receiverCity={this.state.registerData.client.cityName}
                receiverStateLabel='Región'
                receiverState={this.state.registerData.client.stateName}
                additionalInstructions='Lleve este presupuesto a caja para finalizar la venta'
                expirationMessage='Documento válido sólo por 24 horas desde la fecha de emisión'
                shoppingCart={this.props.shoppingCart}
                showTax={true}
                unitLabel=' c/u'
                onRenderComplete={this.onDocumentLoaded}
            />
        );
    };

    renderWaitingPayment = () => {
        return (
            <WaitingPayment
                title1='Paga en el POS'
                title2='Esperando confirmación de pago'
                titleLineBreak={true}
                cardOptionImage={this.props.cardOptionImage || defaultCardOptionImage}
                remainingTime={this.state.maxPaymentTime - this.state.elapsedPaymentTime}
            />
        );
    };

    renderPaymentExpired = () => {
        return (
            <div className='payment-modal-error'>
                <DataError
                    message='No se ha recibido confirmación del pago'
                    actionLabel='Reintentar'
                    action={this.retryPayment}
                    actionButtonLabelColor={this.props.actionButtonLabelColor}
                    actionButtonBackgroundColor={this.props.actionButtonBackgroundColor}
                    actionButtonBorderColor={this.props.actionButtonBorderColor}
                    secondaryActionLabel='Cerrar'
                    secondaryAction={() => this.handleClose('custom-close-button')}
                    secondaryActionButtonLabelColor={this.props.cancelButtonLabelColor}
                    secondaryActionButtonBackgroundColor={this.props.cancelButtonBackgroundColor}
                    secondaryActionButtonBorderColor={this.props.cancelButtonBorderColor}
                />
            </div>
        );
    };

    renderPaymentError = () => {
        return (
            <div className='payment-modal-error'>
                <DataError
                    message={this.state.paymentErrorMessage}
                    actionLabel='Reintentar'
                    action={this.retryPayment}
                    actionButtonLabelColor={this.props.actionButtonLabelColor}
                    actionButtonBackgroundColor={this.props.actionButtonBackgroundColor}
                    actionButtonBorderColor={this.props.actionButtonBorderColor}
                    secondaryActionLabel='Cerrar'
                    secondaryAction={() => this.handleClose('custom-close-button')}
                    secondaryActionButtonLabelColor={this.props.cancelButtonLabelColor}
                    secondaryActionButtonBackgroundColor={this.props.cancelButtonBackgroundColor}
                    secondaryActionButtonBorderColor={this.props.cancelButtonBorderColor}
                />
            </div>
        );
    };

    renderPaymentSuccess = () => {
        return (
            <PaymentMessage
                isLoading={false}
                loaded={true}
                showPreview={false}
                preview={undefined}
                previewSize={undefined}
                title1='Pago completado'
                title2=' Retira tu comprobante de pago'
                loadingMessage={undefined}
                step1Message1='Pago completado'
                step1Message2=' Retira tu comprobante'
                step1Image={defaultSuccessImage}
                step2Message1='Acércate al mesón'
                step2Message2=' y retira tus productos'
                step2Image={this.props.invoiceOptionImage || defaultInvoiceOptionImage}
                errorMessage={undefined}
                actionLabel='Listo'
                actionButtonBackgroundColor={this.props.actionButtonBackgroundColor}
                actionButtonBorderColor={this.props.actionButtonBorderColor}
                actionButtonLabelColor={this.props.actionButtonLabelColor}
                cancelButtonBackgroundColor={this.props.cancelButtonBackgroundColor}
                cancelButtonBorderColor={this.props.cancelButtonBorderColor}
                cancelButtonLabelColor={this.props.cancelButtonLabelColor}
                onClose={undefined}
                onSubmit={() => this.handleClose('custom-close-button')}
                submitted={false}
                submitSuccess={false}
                submitSuccessMessage={undefined}
                submitErrorMessage={undefined}
            />
        );
    };

    render() {
        return (
            <div ref={this._containerRef} className='integrated-pos-container'>
                {this.renderStep()}
            </div>
        );
    };
}

IntegratedPOS.propTypes = {
    store: PropTypes.object,
    shoppingCart: PropTypes.object,
    mainClientLogo: PropTypes.string,
    personOptionImage: PropTypes.string,
    companyOptionImage: PropTypes.string,
    cardOptionImage: PropTypes.string,
    otherPaymentOptionImage: PropTypes.string,
    billOptionImage: PropTypes.string,
    invoiceOptionImage: PropTypes.string,
    printMessageImage1: PropTypes.string,
    printMessageImage2: PropTypes.string,
    actionButtonBackgroundColor: PropTypes.string,
    actionButtonBorderColor: PropTypes.string,
    actionButtonLabelColor: PropTypes.string,
    cancelButtonBackgroundColor: PropTypes.string,
    cancelButtonBorderColor: PropTypes.string,
    cancelButtonLabelColor: PropTypes.string,
    backButtonBackgroundColor: PropTypes.string,
    backButtonBorderColor: PropTypes.string,
    backButtonLabelColor: PropTypes.string,
    customInputOutline: PropTypes.string,
    customInputClearButtonBackgroundColor: PropTypes.string,
    customInputClearButtonLabelColor: PropTypes.string,
    inSmallMode: PropTypes.bool,
    onClose: PropTypes.func,
    allowClosing: PropTypes.func,
    preventClosing: PropTypes.func,
    metadata: PropTypes.object
};