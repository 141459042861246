import React, {useEffect, useState} from 'react';
import Modal from '../Modal/Modal';
import ExternalCheckout from './PaymentFlows/ExternalCheckout/ExternalCheckout';
import IntegratedPOS from './PaymentFlows/IntegratedPOS/IntegratedPOS';
import Signup from './PaymentFlows/Signup/Signup';
import DataError from '../DataError/DataError';
import DataLoader from '../DataLoader/DataLoader';
import Activity from '../../helpers/Activity';
import Viewport from '../../helpers/Viewport';
import WebServices from '../../helpers/WebServices';
import './PaymentModal.css';

function PaymentModal({
    client,
    store,
    shoppingCart,
    paymentMode,
    mainClientLogo,
    kioskOptionImage,
    smartphoneOptionImage,
    smartphoneOptionQrScanImage,
    personOptionImage,
    companyOptionImage,
    cardOptionImage,
    otherPaymentOptionImage,
    billOptionImage,
    invoiceOptionImage,
    printMessageImage1,
    printMessageImage2,
    actionButtonBackgroundColor,
    actionButtonBorderColor,
    actionButtonLabelColor,
    cancelButtonBackgroundColor,
    cancelButtonBorderColor,
    cancelButtonLabelColor,
    backButtonBackgroundColor,
    backButtonBorderColor,
    backButtonLabelColor,
    customInputOutline,
    customInputClearButtonBackgroundColor,
    customInputClearButtonLabelColor,
    onClose,
    metadata
}) {
    const [isLoadingCheckout, setIsLoadingCheckout] = useState(true);
    const [checkoutLoaded, setCheckoutLoaded] = useState(false);
    const [checkoutUrl, setCheckoutUrl] = useState(undefined);
    const [allowClose, setAllowClose] = useState(true);
    const viewport = Viewport.dimensions;
    const smallModeTreshold = {width: 1000, height: 1150};

    useEffect(() => {
        WebServices.getClientShoppingCartUrl(client, store.id, shoppingCart, false)
            .then(response => {
                if (response) {
                    shoppingCart.dbId = response.id;
                    setCheckoutUrl(response.url);
                    setCheckoutLoaded(!!response.url);
                    sendAlgoliaInsight();
                } else {
                    setCheckoutUrl(undefined);
                    setCheckoutLoaded(false);
                }
                setIsLoadingCheckout(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isLoading = () => {
        return isLoadingCheckout;
    };

    const dataLoaded = () => {
        return checkoutLoaded;
    };

    const inSmallMode = () => {
        return viewport.width <= smallModeTreshold.width || viewport.height <= smallModeTreshold.height;
    };

    const handleClose = (closeType) => {
        if (!allowClose) return;
        if (closeType !== 'silent') Activity.log(metadata, 'checkout', closeType, 'close', undefined);
        if (shoppingCart) shoppingCart.dbId = undefined;
        if (onClose) onClose();
    };

    const silentClose = () => {
        handleClose('silent');
    };

    const allowClosing = () => {
        setAllowClose(true);
    };

    const preventClosing = () => {
        setAllowClose(false);
    };

    const sendAlgoliaInsight = () => {
        if (metadata && metadata.algoliaInsights) {
            const algoliaInsights = metadata.algoliaInsights;
            const items = shoppingCart.items;
            const queryIds = items.map(item => item.queryId);
            const queryId = queryIds.length > 0 ? queryIds[0] : undefined;
            const matches = queryId ? items.filter(item => item.queryId === queryId) : [];
            const sameQueryId = matches.length === items.length;
            const productsIds = items.map(item => item.product);
            if (metadata && metadata.session && metadata.session.id) {
                if (sameQueryId) algoliaInsights.convertedObjectIDsAfterSearch(metadata.session.id, queryId, productsIds);
                else algoliaInsights.convertedObjectIDs(metadata.session.id, productsIds);
            }
        }
    };

    const renderLoader = () => {
        return (
            <div className='payment-modal-loader'>
                <DataLoader message='Iniciando interfaz de pago...'/>
            </div>
        );
    };

    const renderError = () => {
        return (
            <div className='payment-modal-error'>
                <DataError
                    message='Se ha producido un error al iniciar el proceso de checkout'
                    actionLabel='Cerrar'
                    action={() => handleClose('checkout-error-close')}
                    actionButtonLabelColor={actionButtonLabelColor}
                    actionButtonBackgroundColor={actionButtonBackgroundColor}
                    actionButtonBorderColor={actionButtonBorderColor}
                />
            </div>
        );
    };

    const renderFlow = () => {
        switch (paymentMode) {
            case 'external-checkout':
                return renderExternalCheckout();
            case 'integrated-pos':
                return renderIntegratedPOS();
            case 'budget-print':
                return renderBudgetPrint();
            default:
                return renderInvalidFlow();
        }
    };

    const renderExternalCheckout = () => {
        return (
            <ExternalCheckout
                checkoutUrl={checkoutUrl}
                shoppingCart={shoppingCart}
                smartphoneOptionImage={smartphoneOptionImage}
                kioskOptionImage={kioskOptionImage}
                smartphoneOptionQrScanImage={smartphoneOptionQrScanImage}
                actionButtonBackgroundColor={actionButtonBackgroundColor}
                actionButtonBorderColor={actionButtonBorderColor}
                actionButtonLabelColor={actionButtonLabelColor}
                cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                cancelButtonBorderColor={cancelButtonBorderColor}
                cancelButtonLabelColor={cancelButtonLabelColor}
                backButtonBackgroundColor={backButtonBackgroundColor}
                backButtonBorderColor={backButtonBorderColor}
                backButtonLabelColor={backButtonLabelColor}
                onClose={silentClose}
                metadata={metadata}
            />
        );
    };

    const renderIntegratedPOS = () => {
        return (
            <IntegratedPOS
                store={store}
                shoppingCart={shoppingCart}
                mainClientLogo={mainClientLogo}
                personOptionImage={personOptionImage}
                companyOptionImage={companyOptionImage}
                cardOptionImage={cardOptionImage}
                otherPaymentOptionImage={otherPaymentOptionImage}
                billOptionImage={billOptionImage}
                invoiceOptionImage={invoiceOptionImage}
                printMessageImage1={printMessageImage1}
                printMessageImage2={printMessageImage2}
                actionButtonBackgroundColor={actionButtonBackgroundColor}
                actionButtonBorderColor={actionButtonBorderColor}
                actionButtonLabelColor={actionButtonLabelColor}
                cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                cancelButtonBorderColor={cancelButtonBorderColor}
                cancelButtonLabelColor={cancelButtonLabelColor}
                backButtonBackgroundColor={backButtonBackgroundColor}
                backButtonBorderColor={backButtonBorderColor}
                backButtonLabelColor={backButtonLabelColor}
                customInputOutline={customInputOutline}
                customInputClearButtonBackgroundColor={customInputClearButtonBackgroundColor}
                customInputClearButtonLabelColor={customInputClearButtonLabelColor}
                inSmallMode={inSmallMode()}
                onClose={silentClose}
                allowClosing={allowClosing}
                preventClosing={preventClosing}
                metadata={metadata}
            />
        );
    };

    const renderBudgetPrint = () => {
        return (
            <Signup
                flow='budget-print'
                initialData={undefined}
                store={store}
                shoppingCart={shoppingCart}
                allowBudgetPrint={true}
                mainClientLogo={mainClientLogo}
                personOptionImage={personOptionImage}
                companyOptionImage={companyOptionImage}
                printMessageImage1={printMessageImage1}
                printMessageImage2={printMessageImage2}
                actionButtonBackgroundColor={actionButtonBackgroundColor}
                actionButtonBorderColor={actionButtonBorderColor}
                actionButtonLabelColor={actionButtonLabelColor}
                cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                cancelButtonBorderColor={cancelButtonBorderColor}
                cancelButtonLabelColor={cancelButtonLabelColor}
                backButtonBackgroundColor={backButtonBackgroundColor}
                backButtonBorderColor={backButtonBorderColor}
                backButtonLabelColor={backButtonLabelColor}
                customInputOutline={customInputOutline}
                customInputClearButtonBackgroundColor={customInputClearButtonBackgroundColor}
                customInputClearButtonLabelColor={customInputClearButtonLabelColor}
                inSmallMode={inSmallMode()}
                onClose={silentClose}
                onRegisterFinished={undefined}
                metadata={metadata}
            />
        )
    };

    const renderInvalidFlow = () => {
        return (
            <div className='payment-modal-error'>
                <DataError
                    message='Flujo de pago inválido. Por favor, contacte a soporte técnico.'
                    actionLabel='Cerrar'
                    action={() => handleClose('invalid-flow-close')}
                    actionButtonLabelColor={actionButtonLabelColor}
                    actionButtonBackgroundColor={actionButtonBackgroundColor}
                    actionButtonBorderColor={actionButtonBorderColor}
                />
            </div>
        );
    };

    return (
        <Modal mode='center' smallMode={inSmallMode()} backgroundColor='#FFFFFF' borderRadius={{topLeft: 50, topRight: 50, bottomLeft: 50, bottomRight: 50}} padding={0} width={1000} maxWidth='100%' height='auto' maxHeight='100%' showDefaultCloseButton={false} onClose={handleClose}>
            {isLoading() && renderLoader()}
            {!isLoading() && !dataLoaded() && renderError()}
            {!isLoading() && dataLoaded() && renderFlow()}
        </Modal>
    );
}

export default PaymentModal;