import React, {useState, useRef, useEffect} from 'react';
import CrossLoader from '../../CrossLoader/CrossLoader';
import LinkButton from '../../LinkButton/LinkButton';
import ImageWrapper from "../../ImageWrapper/ImageWrapper";
import Viewport from "../../../helpers/Viewport";
import Currency from '../../../config/Currency';
import CurrencyFormat from 'react-currency-format';
import noImage from './images/no-image.png';
import './ShoppingCartItem.css';

function ShoppingCartItem({
    item,
    product,
    valid,
    discountBackgroundColor,
    discountLabelColor,
    exclusivePriceLabelColor,
    exclusivePriceIcon,
    onQuantityIncrease,
    onQuantityDecrease,
    onDelete
}) {
    const imageContainerRef = useRef(null);
    const imageRef = useRef(null);
    const [isLoadingImage, setIsLoadingImage] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);
    const currencyFormat = Currency.CLP;
    const viewport = Viewport.dimensions;

    useEffect(() => {
        const image = imageRef.current;
        if (!image) return;
        image.onload = () => {
            setTimeout(() => {
                setIsLoadingImage(false);
                setImageLoaded(true);
            }, 1000);
        };
        image.onerror = () => {
            setTimeout(() => {
                setIsLoadingImage(false);
                setImageLoaded(false);
            }, 1000);
        };
        image.src = getImage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const container = imageContainerRef.current;
        const image = imageRef.current;
        if (!image) return;
        const newImage = getImage();
        const oldImage = image.src;
        if (newImage) {
            if (newImage !== oldImage) {
                setIsLoadingImage(true);
                setTimeout(() => {
                    image.src = newImage;
                    container.style.display = 'block';
                }, 500);
            }
        } else {
            image.src = undefined;
            container.style.display = 'none';
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product.getMainImage('m')]);

    const inSmallMode = () => {
        return viewport.width <= 1000;
    };

    const getImage = () => {
        const image = product.getMainImage('m');
        if (!image) return undefined;
        return !isLoadingImage && !imageLoaded ? noImage : image;
    };

    const totalPricePerProduct = () => {
        const price = product.exclusivePrice || product.offerPrice || product.normalPrice;
        return price * item.quantity;
    };

    const hasAnyPrice = () => {
        return product.normalPrice || product.offerPrice || product.exclusivePrice;
    };

    const handleDeleteClick = () => {
        if (onDelete) onDelete(item.id);
    };

    const handleQuantityIncreaseClick = () => {
        if (onQuantityIncrease) onQuantityIncrease(item.id);
    };

    const handleQuantityDecreaseClick = () => {
        if (onQuantityDecrease) onQuantityDecrease(item.id);
    };

    const imageStyle = () => {
        return {
            opacity: isLoadingImage ? 0 : 1
        };
    };

    const loaderStyle = () => {
        return {
            opacity: isLoadingImage ? 1 : 0
        };
    };

    const exclusivePriceLabelStyle = () => {
        return {
            color: exclusivePriceLabelColor || '#005B80'
        };
    };

    const discountStyle = () => {
        return {
            background: discountBackgroundColor || '#005B80',
            color: discountLabelColor || '#FFFFFF'
        };
    };

    const renderAsExclusivePrice = (price, discount) => {
        return (
            <div className='shopping-cart-item-price exclusive' style={exclusivePriceLabelStyle()}>
                <CurrencyFormat
                    prefix={currencyFormat.prefix}
                    suffix={currencyFormat.suffix}
                    thousandSeparator={currencyFormat.thousandsSeparator}
                    decimalSeparator={currencyFormat.decimalSeparator}
                    decimalScale={currencyFormat.decimalScale}
                    value={price}
                    displayType='text'
                />
                {exclusivePriceIcon && (
                    <div className='shopping-cart-item-card'>
                        <ImageWrapper image={exclusivePriceIcon} alt='Card' display='block' width={35} height='auto' loadDelay={0.2}/>
                    </div>
                )}
                {discount && discount > 0 ? <div className='shopping-cart-item-discount' style={discountStyle()}>{discount}%</div> : null}
            </div>
        );
    };

    const renderAsOfferPrice = (price, discount) => {
        return (
            <div className='shopping-cart-item-price offer'>
                <CurrencyFormat
                    prefix={currencyFormat.prefix}
                    suffix={currencyFormat.suffix}
                    thousandSeparator={currencyFormat.thousandsSeparator}
                    decimalSeparator={currencyFormat.decimalSeparator}
                    decimalScale={currencyFormat.decimalScale}
                    value={price}
                    displayType='text'
                />
                {discount && discount > 0 ? <div className='shopping-cart-item-discount' style={discountStyle()}>{discount}%</div> : null}
            </div>
        );
    };

    const renderAsNormalPrice = (price) => {
        return (
            <div className='shopping-cart-item-price normal'>
                <span>Antes: </span>
                <div className='shopping-cart-item-normal-price-wrapper'>
                    <CurrencyFormat
                        prefix={currencyFormat.prefix}
                        suffix={currencyFormat.suffix}
                        thousandSeparator={currencyFormat.thousandsSeparator}
                        decimalSeparator={currencyFormat.decimalSeparator}
                        decimalScale={currencyFormat.decimalScale}
                        value={price}
                        displayType='text'
                    />
                </div>
            </div>
        );
    };

    const renderTotalPrice = (mode) => {
        return (
            <div className={`shopping-cart-final-price ${mode}`}>
                <CurrencyFormat
                    prefix={inSmallMode() ? `Total: ${currencyFormat.prefix}` : currencyFormat.prefix}
                    suffix={currencyFormat.suffix}
                    thousandSeparator={currencyFormat.thousandsSeparator}
                    decimalSeparator={currencyFormat.decimalSeparator}
                    decimalScale={currencyFormat.decimalScale}
                    value={totalPricePerProduct()}
                    displayType='text'
                />
            </div>
        );
    };

    const name = product.name || 'Sin nombre';

    return (
        <div className='shopping-cart-item'>
            <div className='shopping-cart-item-data'>
                {valid && (
                    <React.Fragment>
                        <div ref={imageContainerRef} className='shopping-cart-item-image-wrapper'>
                            <img ref={imageRef} className='shopping-cart-item-image' alt={name} style={imageStyle()}/>
                            <div className='shopping-cart-item-image-loader' style={loaderStyle()}>
                                <CrossLoader/>
                            </div>
                        </div>
                        <div className='shopping-cart-item-details'>
                            <div className='shopping-cart-item-name'>{name}</div>
                            {hasAnyPrice() && (
                                <div className='shopping-cart-item-prices'>
                                    {product.exclusivePrice && renderAsExclusivePrice(product.exclusivePrice, product.getExclusiveDiscountPercentage())}
                                    {product.offerPrice && renderAsOfferPrice(product.offerPrice, product.getOfferDiscountPercentage())}
                                    {product.normalPrice && product.normalPrice !== product.offerPrice && (product.offerPrice ? renderAsNormalPrice(product.normalPrice) : renderAsOfferPrice(product.normalPrice, undefined))}
                                </div>
                            )}
                        </div>
                        {!inSmallMode() && hasAnyPrice() && renderTotalPrice('normal')}
                    </React.Fragment>
                )}
                {!valid && (
                    <div className='shopping-cart-item-error'>Error al cargar el producto ({item.product})</div>
                )}
            </div>
            {inSmallMode() && hasAnyPrice() && renderTotalPrice('small')}
            <div className='shopping-cart-item-actions'>
                <div className='shopping-cart-item-delete-container'>
                    <LinkButton icon={undefined} label='Eliminar' labelColor='#7A797A' onClick={handleDeleteClick}/>
                </div>
                {valid && product.maxPerItem > 0 && product.hasAnyPrice() && (
                    <div className='shopping-cart-item-quantity-container'>
                        <div className={`shopping-cart-item-quantity-decrease ${item.quantity <= item.minQuantity ? 'disabled' : ''}`} onClick={handleQuantityDecreaseClick}>
                            <span className='shopping-cart-item-quantity-content'>-</span>
                        </div>
                        <div className='shopping-cart-item-quantity'>
                            <span className='shopping-cart-item-quantity-content'>{item.quantity}</span>
                        </div>
                        <div className={`shopping-cart-item-quantity-increase ${item.quantity >= item.maxQuantity ? 'disabled' : ''}`} onClick={handleQuantityIncreaseClick}>
                            <span className='shopping-cart-item-quantity-content'>+</span>
                        </div>
                    </div>
                )}
                {valid && product.hasAnyPrice() && product.maxPerItem <= 0 && (
                    <div className='shopping-cart-item-invalid'>Sin stock</div>
                )}
                {valid && !product.hasAnyPrice() && product.maxPerItem > 0 && (
                    <div className='shopping-cart-item-invalid'>Sin precio</div>
                )}
                {valid && !product.hasAnyPrice() && product.maxPerItem <= 0 && (
                    <div className='shopping-cart-item-invalid'>Sin stock ni precio</div>
                )}
            </div>
        </div>
    );
}

export default ShoppingCartItem;