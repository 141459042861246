import React from 'react';
import Button from '../Button/Button';
import errorImage from './images/error.png';
import './DataError.css';

function DataError({
    message,
    action,
    actionLabel,
    actionButtonLabelColor,
    actionButtonBackgroundColor,
    actionButtonBorderColor,
    secondaryAction,
    secondaryActionLabel,
    secondaryActionButtonLabelColor,
    secondaryActionButtonBackgroundColor,
    secondaryActionButtonBorderColor,
    maxWidth
}) {
    const anyAction = () => {
        return (action && actionLabel) || (secondaryAction && secondaryActionLabel);
    };

    const hasAction = () => {
        return action && actionLabel;
    };

    const hasSecondaryAction = () => {
        return secondaryAction && secondaryActionLabel;
    };

    const handleActionClick = () => {
        if (action) action();
    };

    const handleSecondaryActionClick = () => {
        if (secondaryAction) secondaryAction();
    };

    const containerStyle = () => {
        return {
            maxWidth: maxWidth ? maxWidth : 300
        };
    };

    const renderButton = (label, labelColor, backgroundColor, borderColor, boxShadow, onClick) => {
        return (
            <div className='data-error-action'>
                <Button label={label} labelColor={labelColor} backgroundColor={backgroundColor} borderColor={borderColor} minWidth='auto' maxWidth='100%' boxShadow={boxShadow} onClick={onClick}/>
            </div>
        );
    };

    return (
        <div className='data-error' style={containerStyle()}>
            <img className='data-error-image' src={`${errorImage}`} alt='Error'/>
            <p className='data-error-message'>{message}</p>
            {anyAction() && (
                <React.Fragment>
                    <div className='data-error-actions'>
                        {hasSecondaryAction() && renderButton(
                            secondaryActionLabel ? secondaryActionLabel : '',
                            secondaryActionButtonLabelColor,
                            secondaryActionButtonBackgroundColor,
                            secondaryActionButtonBorderColor,
                            'unset',
                            handleSecondaryActionClick
                        )}
                        {hasAction() && renderButton(
                            actionLabel ? actionLabel : '',
                            actionButtonLabelColor,
                            actionButtonBackgroundColor,
                            actionButtonBorderColor,
                            undefined,
                            handleActionClick
                        )}
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

export default DataError;