import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from '../../helpers/WithRouter';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import ProductBox from "../ProductBox/ProductBox";
import DataLoader from '../DataLoader/DataLoader';
import Viewport from '../../helpers/Viewport';
import Activity from '../../helpers/Activity';
import Debug from '../../helpers/Debug';
import Random from '../../helpers/Random';
import ObjectAddons from "../../helpers/ObjectAddons";
import Navigation from '../../config/Navigation';
import {firestore, getCollectionPath} from '../../config/Firebase';
import {collection, doc, getDoc, getDocs, onSnapshot, query, where} from 'firebase/firestore';
import {productConverter} from '../../models/Product';
import {categoryConverter} from '../../models/Category';
import './CrossSellModal.css';

class CrossSellModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            product: undefined,
            deepLevel: 1,
            siblings: [],
            randomSiblings: [],
            relatedProducts: [],
            selectedRelatedProductsIds: [],
            selectedRelatedProducts: []
        };
        this._smallModeTreshold = {width: 1000, height: 850};
        this._unsubscribeSelectedRelatedProducts = [];
        this._isMounted = true;
    };

    changeState = (newState, callback) => {
        if (this._isMounted) {
            this.setState(newState, () => {
                if (callback) callback();
            });
        }
    };

    componentDidMount() {
        this._isMounted = true;
        const maxPriceItem = this.props.shoppingCart.getMaxPriceItem();
        const productId = maxPriceItem ? (maxPriceItem.parent ? maxPriceItem.parent : maxPriceItem.product) : undefined;
        this.getProduct(productId);
    };

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
    };

    getProduct = (productId) => {
        if (productId) {
            const _path = getCollectionPath(this.props.client, 'items');
            const _doc = doc(firestore, _path, productId).withConverter(productConverter);
            getDoc(_doc)
                .then((_document) => {
                    if (_document.exists()) {
                        const product = _document.data();
                        this.changeState({product: product}, this.getSiblings);
                    } else this.onLoadError();
                })
                .catch((error) => {
                    Debug.printToLog('error', error);
                    this.onLoadError();
                });
        } else this.onLoadError();
    };

    getSiblings = () => {
        const {product, deepLevel} = this.state;
        let level1Categories = [];
        if (!product.categoryPaths || product.categoryPaths.length === 0) {
            this.onLoadError();
            return;
        }
        level1Categories = product.categoryPaths.map((path) => ({parent: path[deepLevel - 1], category: path[deepLevel]}));

        // Commented because it may respond invalid paths.
        // const randomIndex = level1Categories.length > 0 ? Random.between(0, level1Categories.length - 1) : undefined;
        // const randomCategory = randomIndex !== undefined && randomIndex >= 0 ? level1Categories[randomIndex] : undefined;

        const randomCategory = level1Categories && level1Categories.length > 0 ? level1Categories[0] : undefined;
        const parent = randomCategory ? randomCategory.parent : undefined;
        if (!parent) {
            this.onLoadError();
            return;
        }
        const _path = getCollectionPath(this.props.client, 'categories');
        const _collection = collection(firestore, _path);
        const _where1 = where('parents', 'array-contains', parent);
        const _where2 = where('is_empty', '==', false);
        const _where3 = where('is_visible', '==', true);
        const _where4 = where('deep_level', '==', 1);
        const _query = query(_collection, _where1, _where2, _where3, _where4).withConverter(categoryConverter);
        getDocs(_query)
            .then((_snapshot) => {
                let {siblings} = this.state;
                _snapshot.docChanges().forEach((change) => {
                    const id = change.doc.id;
                    const data = change.doc.data();
                    if (randomCategory.id !== id) {
                        if (change.type === 'added') {
                            siblings.push(data);
                        } else if (change.type === 'modified') {
                            const index = siblings.findIndex(sibling => sibling.id === id);
                            if (index >= 0) siblings[index] = data;
                        } else if (change.type === 'removed') {
                            const index = siblings.findIndex(sibling => sibling.id === id);
                            if (index >= 0) siblings.splice(index, 1);
                        }
                    }
                });
                this.changeState({siblings: siblings}, this.getRelatedProducts);
            })
            .catch((error) => {
                Debug.printToLog('error', error);
                this.onLoadError();
            });
    };

    getRelatedProducts = () => {
        const {siblings} = this.state;
        if (siblings && siblings.length > 0) {
            const clone = ObjectAddons.clone(siblings);
            let iterations = 1;
            const maxIterations = 30;
            const randomSiblings = [];
            while (randomSiblings.length < 3 && iterations < maxIterations) {
                const randomIndex = Random.between(0, clone.length - 1);
                const randomSibling = clone[randomIndex];
                const exists = randomSiblings.find(sibling => sibling.id === randomSibling.id);
                if (!exists) randomSiblings.push(randomSibling);
                iterations++;
            }
            const _path = getCollectionPath(this.props.client, 'items');
            const _collection = collection(firestore, _path);
            const _where1 = where('categories', 'array-contains-any', randomSiblings.map(sibling => sibling.id));
            const _where2 = where('is_external_site_sell', '==', false);
            const _query = query(_collection, _where1, _where2).withConverter(productConverter);
            getDocs(_query)
                .then((_snapshot) => {
                    let {product, relatedProducts} = this.state;
                    _snapshot.docChanges().forEach((change) => {
                        const id = change.doc.id;
                        const data = change.doc.data();
                        if (product.id !== id) {
                            if (change.type === 'added') {
                                relatedProducts.push(data);
                            } else if (change.type === 'modified') {
                                const index = relatedProducts.findIndex(banner => banner.id === id);
                                if (index >= 0) relatedProducts[index] = data;
                            } else if (change.type === 'removed') {
                                const index = relatedProducts.findIndex(banner => banner.id === id);
                                if (index >= 0) relatedProducts.splice(index, 1);
                            }
                        }
                    });
                    this.changeState({randomSiblings: randomSiblings, relatedProducts: relatedProducts}, () => {
                        this.selectRelatedProducts();
                    });
                })
                .catch((error) => {
                    Debug.printToLog('error', error);
                    this.onLoadError();
                });
        } else this.onLoadError();
    };

    selectRelatedProducts = () => {
        const {randomSiblings, relatedProducts} = this.state;
        const productsBySibling = [];
        for (let i = 0; i < randomSiblings.length; i++) {
            const sibling = randomSiblings[i];
            productsBySibling.push({sibling: sibling, products: relatedProducts.filter(product => product.categories.includes(sibling.id))});
        }
        const selectedRelatedProductsIds = [];
        let index = 0;
        let iterations = 1;
        const maxIterations = 30;
        while (selectedRelatedProductsIds.length < 3 && iterations < maxIterations) {
            const products = productsBySibling[index].products;
            const product = products.reduce((previous, current) => {
                const previousMaxDiscount = previous.maxDiscount();
                const currentMaxDiscount = current.maxDiscount();
                return previousMaxDiscount > currentMaxDiscount ? previous : current;
            }, products[0]);
            const exists = product ? selectedRelatedProductsIds.find(id => id === product.id) : false;
            if (product && !exists) {
                selectedRelatedProductsIds.push(product.id);
                const indexInGroup = products.findIndex(p => p.id === product.id);
                if (indexInGroup >= 0) products.splice(indexInGroup, 1);
            }
            index++;
            iterations++;
            if (index >= productsBySibling.length) index = 0;
        }
        this.changeState({selectedRelatedProductsIds: selectedRelatedProductsIds}, this.listenToSelectedRelatedProducts);
    };

    listenToSelectedRelatedProducts = () => {
        const {selectedRelatedProductsIds} = this.state;
        selectedRelatedProductsIds.forEach((productId) => {
            const _path = getCollectionPath(this.props.client, 'items');
            const _doc = doc(firestore, _path, productId).withConverter(productConverter);
            const unsubscribe = onSnapshot(_doc, (_document) => {
                if (_document.exists()) {
                    const {selectedRelatedProducts} = this.state;
                    const product = _document.data();
                    const matchIndex = selectedRelatedProducts.findIndex(product => product.id === productId);
                    if (matchIndex >= 0) selectedRelatedProducts[matchIndex] = product;
                    else selectedRelatedProducts.push(product);
                    this.changeState({selectedRelatedProducts: selectedRelatedProducts});
                }
            }, (error) => {
                Debug.printToLog('error', error);
            });
            this._unsubscribeSelectedRelatedProducts.push(unsubscribe);
        });
    };

    onLoadError = () => {
        this.changeState({
            product: undefined,
            siblings: [],
            randomSiblings: [],
            relatedProducts: [],
            selectedRelatedProductsIds: [],
            selectedRelatedProducts: []
        }, () => {
            this.toCheckout('automatic');
        });
    };

    unsubscribe = () => {
        if (this._unsubscribeSelectedRelatedProducts.length > 0) {
            this._unsubscribeSelectedRelatedProducts.forEach(unsubscribe => unsubscribe());
            this._unsubscribeSelectedRelatedProducts = [];
        }
    };

    inSmallMode = () => {
        const viewport = Viewport.dimensions;
        return viewport.width <= this._smallModeTreshold.width || viewport.height <= this._smallModeTreshold.height;
    };

    silentClose = () => {
        if (this.props.onClose) this.props.onClose();
    };

    handleClose = (closeType) => {
        if (this.props.onClose) {
            const {metadata} = this.props;
            Activity.log(metadata, 'cross-sell-modal', closeType, 'close', undefined);
            this.props.onClose();
        }
    };

    inHome = () => {
        return window.location.pathname === Navigation.getHomeUrl();
    };

    toHome = () => {
        const {metadata} = this.props;
        Activity.log(metadata, 'cross-sell-modal', 'home-button', 'redirect', undefined);
        const url = Navigation.getHomeUrl();
        if (this.inHome()) this.silentClose();
        else this.props.navigate(url);
    };

    toCheckout = (redirectionType) => {
        if (this.props.onCheckout) {
            const {shoppingCart, metadata} = this.props;
            Activity.log(metadata, 'cross-sell-modal', 'checkout-button', 'checkout', {redirectionType: redirectionType, shoppingCart: shoppingCart.toActivityLog()});
            this.props.onCheckout();
        }
        this.silentClose();
    };

    onProductClick = (category, product) => {
        const {metadata} = this.props;
        const algoliaInsights = metadata.algoliaInsights;
        Activity.log(metadata, 'related-product', product.id, 'click', {
            from: 'cross-sell-modal',
            source_product: {product_id: this.state.product.id, product_name: this.state.product.name},
            related_product: {product_id: product.id, product_name: product.name}
        });
        if (metadata && metadata.session && metadata.session.id) algoliaInsights.clickedObjectIDs(metadata.session.id, [product.id]);
        const url = Navigation.getProductUrl(product.id, undefined, metadata.queryId);
        Navigation.forceRedirect(url);
    };

    renderRelatedProducts = () => {
        return this.state.selectedRelatedProducts.map((product) => (
            <ProductBox
                key={`related-product-${product.id}`}
                index={undefined}
                category={undefined}
                product={product}
                showSku={true}
                showIcons={true}
                active={false}
                showBorder={false}
                clientName={this.props.clientName}
                _3dIcon={this.props._3dIcon}
                featuredIcon={this.props.featureIcon}
                discountBackgroundColor={this.props.discountBackgroundColor}
                discountLabelColor={this.props.discountLabelColor}
                exclusivePriceLabelColor={this.props.exclusivePriceLabelColor}
                exclusivePriceIcon={this.props.exclusivePriceIcon}
                offerBackgroundColor={this.props.offerBackgroundColor}
                offerLabelColor={this.props.offerLabelColor}
                showTags={true}
                freeDeliveryIcon={this.props.freeDeliveryIcon}
                freeDeliveryLabelColor={this.props.freeDeliveryLabelColor}
                freeDeliveryBackgroundColor={this.props.freeDeliveryBackgroundColor}
                deliveryIcon={this.props.deliveryIcon}
                deliveryLabelColor={this.props.deliveryLabelColor}
                deliveryBackgroundColor={this.props.deliveryBackgroundColor}
                pickupIcon={this.props.pickupIcon}
                pickupLabelColor={this.props.pickupLabelColor}
                pickupBackgroundColor={this.props.pickupBackgroundColor}
                sellerLabelColor={this.props.sellerLabelColor}
                mainSellerIcon={this.props.mainSellerIcon}
                visible={true}
                disabled={false}
                onClick={this.onProductClick}
            />
        ));
    };

    render() {
        return (
            <Modal mode='center' smallMode={this.inSmallMode()} backgroundColor='#FFFFFF' borderRadius={{topLeft: 50, topRight: 50, bottomLeft: 50, bottomRight: 50}} padding={40} width={1000} maxWidth='100%' height='auto' maxHeight='100%' showDefaultCloseButton={false} onClose={this.handleClose}>
                <div className='cross-sell-modal'>
                    {this.state.selectedRelatedProducts.length === 0 && (
                        <div className='cross-sell-modal-loader'>
                            <DataLoader message='Iniciando proceso de checkout...'/>
                        </div>
                    )}
                    {this.state.selectedRelatedProducts.length > 0 && (
                        <React.Fragment>
                            <p className='cross-sell-modal-title'><span className='bold'>¿Quieres agregar algo más al carro?</span></p>
                            <p className='cross-sell-modal-description'>Te dejamos algunas opciones relacionadas a tu compra</p>
                            <div className='cross-sell-modal-content'>
                                {this.renderRelatedProducts()}
                            </div>
                            <div className='cross-sell-modal-actions'>
                                <div className='cross-sell-modal-action'>
                                    <Button label='Ir a categorías' labelColor={this.props.cancelButtonLabelColor} backgroundColor={this.props.cancelButtonBackgroundColor} borderColor={this.props.cancelButtonBorderColor} minWidth='auto' maxWidth='100%' boxShadow='unset' onClick={this.toHome}/>
                                </div>
                                <div className='cross-sell-modal-action'>
                                    <Button label='No, quiero pagar' labelColor={this.props.actionButtonLabelColor} backgroundColor={this.props.actionButtonBackgroundColor} borderColor={this.props.actionButtonBorderColor} minWidth='auto' maxWidth='100%' boxShadow='unset' onClick={() => this.toCheckout('manual')}/>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </Modal>
        );
    };
}

export default withRouter(CrossSellModal);

CrossSellModal.propTypes = {
    catalog: PropTypes.object,
    client: PropTypes.string,
    shoppingCart: PropTypes.object,
    actionButtonBackgroundColor: PropTypes.string,
    actionButtonBorderColor: PropTypes.string,
    actionButtonLabelColor: PropTypes.string,
    cancelButtonBackgroundColor: PropTypes.string,
    cancelButtonBorderColor: PropTypes.string,
    cancelButtonLabelColor: PropTypes.string,
    clientName: PropTypes.string,
    _3dIcon: PropTypes.string,
    featureIcon: PropTypes.string,
    discountBackgroundColor: PropTypes.string,
    discountLabelColor: PropTypes.string,
    exclusivePriceLabelColor: PropTypes.string,
    exclusivePriceIcon: PropTypes.string,
    offerBackgroundColor: PropTypes.string,
    offerLabelColor: PropTypes.string,
    freeDeliveryIcon: PropTypes.string,
    freeDeliveryLabelColor: PropTypes.string,
    freeDeliveryBackgroundColor: PropTypes.string,
    deliveryIcon: PropTypes.string,
    deliveryLabelColor: PropTypes.string,
    deliveryBackgroundColor: PropTypes.string,
    pickupIcon: PropTypes.string,
    pickupLabelColor: PropTypes.string,
    pickupBackgroundColor: PropTypes.string,
    sellerLabelColor: PropTypes.string,
    mainSellerIcon: PropTypes.string,
    onCheckout: PropTypes.func,
    onClose: PropTypes.func,
    metadata: PropTypes.object
};