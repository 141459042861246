import React, {createRef} from 'react';
import PropTypes from 'prop-types';
import PaymentIdentification from '../../PaymentSteps/PaymentIdentification/PaymentIdentification';
import PaymentOptionSelection from '../../PaymentSteps/PaymentOptionSelection/PaymentOptionSelection';
import PaymentRegister from '../../PaymentSteps/PaymentRegister/PaymentRegister';
import PaymentMessage from "../../PaymentSteps/PaymentMessage/PaymentMessage";
import Budget from '../../PaymentDocuments/Budget/Budget';
import DataLoader from '../../../DataLoader/DataLoader';
import Pin from '../../../../helpers/Pin';
import Search from '../../../../helpers/Search';
import Image from '../../../../helpers/Image';
import WebServices from '../../../../helpers/WebServices';
import Activity from '../../../../helpers/Activity';
import Printer from '../../../../helpers/Printer';
import defaultPersonOptionImage from './images/person-option.png';
import defaultCompanyOptionImage from './images/company-option.png';
import defaultPrintMessageImage1 from './images/message-step-one.png';
import defaultPrintMessageImage2 from './images/message-step-two.png';
import './Signup.css';

export default class Signup extends React.Component {
    constructor(props) {
        super(props);
        const entity             = this.props.initialData && this.props.initialData.entity   ? this.props.initialData.entity   : undefined;
        const pin      = this.props.initialData && this.props.initialData.pin      ? this.props.initialData.pin      : undefined;
        const name     = this.props.initialData && this.props.initialData.name     ? this.props.initialData.name     : undefined;
        const phone    = this.props.initialData && this.props.initialData.phone    ? this.props.initialData.phone    : undefined;
        const email    = this.props.initialData && this.props.initialData.email    ? this.props.initialData.email    : undefined;
        const activity = this.props.initialData && this.props.initialData.activity ? this.props.initialData.activity : undefined;
        const state    = this.props.initialData && this.props.initialData.state    ? this.props.initialData.state    : undefined;
        const city     = this.props.initialData && this.props.initialData.city     ? this.props.initialData.city     : undefined;
        const district = this.props.initialData && this.props.initialData.district ? this.props.initialData.district : undefined;
        const street   = this.props.initialData && this.props.initialData.street   ? this.props.initialData.street   : undefined;
        this.state = {
            step: 'identification',
            entity: entity,
            allowEntityChange: true,
            isLoadingEntity: false,
            retries: 0,
            maxRetries: 3,
            pin: pin,
            name: name,
            allowNameChange: true,
            phone: phone,
            email: email,
            activities: [],
            allActivities: [],
            activity: activity,
            isLoadingActivities: true,
            states: [],
            state: state,
            isLoadingStates: true,
            cities: [],
            city: city,
            isLoadingCities: true,
            districts: [],
            district: district,
            isLoadingDistricts: true,
            street: street,
            isLoadingHQData: true,
            companyName: undefined,
            hqAddress: undefined,
            hqActivity: undefined,
            isCreatingBudget: false,
            budgetCreated: false,
            budgetId: undefined,
            budgetLogo: undefined,
            isLoadingBudgetLogo: true,
            budgetPrinted: false,
            budgetPrintError: false
        };
        this._isMounted = true;
        this._containerRef = createRef();
        this._budgetRef = createRef();
    };

    changeState = (newState, callback) => {
        if (this._isMounted) {
            this.setState(newState, () => {
                if (callback) callback();
            });
        }
    };

    componentDidMount() {
        this._isMounted = true;
        this.getActivities();
        this.getStates();
        this.getDistricts();
        this.getCities();
        this.getHQData();
        this.getImageData();
        if (this.state.pin) this.onPreloadFinished(this.state.pin, false);
    };

    componentWillUnmount() {
        this._isMounted = false;
    };

    getActivities = () => {
        WebServices.getActivities()
            .then((response) => {
                this.changeState({allActivities: response, isLoadingActivities: false});
            });
    };

    getStates = () => {
        WebServices.getStates()
            .then((response) => {
                this.changeState({states: response, isLoadingStates: false});
            });
    };

    getDistricts = () => {
        WebServices.getDistricts()
            .then((response) => {
                this.changeState({districts: response, isLoadingDistricts: false});
            });
    };

    getCities = () => {
        WebServices.getCities()
            .then((response) => {
                this.changeState({cities: response, isLoadingCities: false});
            });
    };

    getHQData = () => {
        WebServices.getHQData()
            .then((response) => {
                if (response) {
                    this.changeState({
                        companyName: response['companyName'],
                        hqAddress: response['hqAddress'],
                        hqActivity: response['hqActivity'],
                        isLoadingHQData: false
                    });
                }
            });
    };

    getImageData = () => {
        const {mainClientLogo} = this.props;
        if (mainClientLogo) {
            Image.getBase64(mainClientLogo)
                .then((base64) => {
                    if (base64) {
                        this.changeState({budgetLogo: base64, isLoadingBudgetLogo: false});
                    } else {
                        this.changeState({budgetLogo: undefined, isLoadingBudgetLogo: false});
                    }
                });
        } else this.changeState({budgetLogo: undefined, isLoadingBudgetLogo: false});
    };

    isLoadingInitialData = () => {
        const {isLoadingActivities, isLoadingStates, isLoadingDistricts, isLoadingCities, isLoadingHQData, isLoadingBudgetLogo, isLoadingEntity} = this.state;
        const isLoading = isLoadingActivities || isLoadingStates || isLoadingDistricts || isLoadingCities || isLoadingHQData || isLoadingBudgetLogo;
        if (this.state.pin) return isLoading || isLoadingEntity;
        else return isLoading;
    };

    onPreloadFinished = () => {
        this.changeState({isLoadingEntity: true}, this.getClientEntityData);
    };

    onIdentificationFinished = (pin) => {
        Activity.log(this.props.metadata, 'checkout', this.state.step, 'finish', {type: this.props.flow, shoppingCart: this.props.shoppingCart.toActivityLog()}); // Do not save user personal data.
        this.changeState({pin: pin, isLoadingEntity: true}, this.getClientEntityData);
    };

    getClientEntityData = () => {
        WebServices.getClientEntity(this.state.pin)
            .then((response) => {
                if (response) {
                    const {states, districts, cities} = this.state;
                    const stateName = response['address']['state'];
                    const districtName = response['address']['district'];
                    const cityName = response['address']['city'];
                    const stateMatch = states.find((state) => Search.normalizeText(state['stateName']) === Search.normalizeText(stateName));
                    const districtMatch = districts.find((district) => Search.normalizeText(district['districtName']) === Search.normalizeText(districtName));
                    const cityMatch = cities.find((city) => Search.normalizeText(city['cityName']) === Search.normalizeText(cityName));
                    const stateValue = stateMatch ? stateMatch['stateId'] : undefined;
                    const districtValue = districtMatch ? districtMatch['districtName'] : undefined;
                    const cityValue = cityMatch ? cityMatch['cityKey'] : undefined;
                    this.changeState({
                        entity: response['entity'] || undefined,
                        allowEntityChange: false,
                        name: response['name'] || this.state.name,
                        phone: response['phone'] || this.state.phone,
                        email: response['email'] || this.state.email,
                        state: stateValue || this.state.state,
                        district: districtValue || this.state.district,
                        city: cityValue || this.state.city,
                        street: response['address']['street'] || this.state.street,
                    }, this.getSiiEntityData);
                } else this.getSiiEntityData();
            });
    };

    getSiiEntityData = () => {
        const {entity, retries, maxRetries} = this.state;
        if (retries < maxRetries) {
            WebServices.getSiiEntity(this.state.pin)
                .then((response) => {
                    if (response) {
                        const name = !this.state.name ? response.name : this.state.name;
                        const activities = this.filterValidActivities(response.activities);
                        const activity = activities.length === 1 ? activities[0]["activityId"] : (this.state.activity || undefined);
                        this.changeState({step: entity ? 'client-register' : 'client-entity-selection', isLoadingEntity: false, name: name, allowNameChange: !name, activities: activities, activity: activity});
                    } else this.changeState({retries: retries + 1}, this.getSiiEntityData);
                });
        } else this.changeState({step: entity ? 'client-register' : 'client-entity-selection', isLoadingEntity: false});
    };

    filterValidActivities = (activities) => {
        const {allActivities} = this.state;
        let validActivities = [];
        activities.forEach((activity) => {
            const activityMatch = allActivities.find((a) => Search.normalizeText(a['activityName']) === Search.normalizeText(activity));
            if (activityMatch) validActivities.push(activityMatch);
        });
        return validActivities;
    };

    onEntitySelected = (entity) => {
        Activity.log(this.props.metadata, 'checkout', this.state.step, `${entity.toLowerCase()}-selected`, {type: this.props.flow, entity: entity, shoppingCart: this.props.shoppingCart.toActivityLog()});
        this.changeState({entity: entity, step: 'client-register'});
    };

    getActivityName = (id) => {
        const match = this.state.allActivities.find((activity) => activity['activityId'] === id);
        return match ? match['activityName'] : undefined;
    };

    getCityName = (id) => {
        const match = this.state.cities.find((city) => city['cityKey'] === id);
        return match ? match['cityName'] : undefined;
    };

    getStateName = (id) => {
        const match = this.state.states.find((state) => state['stateId'] === id);
        return match ? match['stateName'] : undefined;
    };

    handlePinChange = () => {
        return null;
    };

    handleActivityChange = (activity) => {
        this.changeState({activity: activity});
    };

    handleNameChange = (data) => {
        this.changeState({name: data.value.toUpperCase()});
    };

    handleAddressChange = (street, district, city, state) => {
        this.changeState({street: street, district: district, city: city, state: state});
    };

    handleStateChange = (state) => {
        this.changeState({state: state, city: undefined, district: undefined});
    };

    handleDistrictChange = (district) => {
        this.changeState({district: district});
    };

    handleCityChange = (city) => {
        this.changeState({city: city});
    };

    handlePhoneChange = (data) => {
        this.changeState({phone: data.value});
    };

    handleEmailChange = (data) => {
        this.changeState({email: data.value});
    };

    onRegisterFinished = () => {
        Activity.log(this.props.metadata, 'checkout', this.state.step, 'finish', {type: this.props.flow, entity: this.state.entity, shoppingCart: this.props.shoppingCart.toActivityLog()});
        // TODO: Also save the client data in client backend when the endpoint is ready.
        // TODO: Also save the budget data in client backend when the endpoint is ready.
        // TODO: Overwrite budgetId variable with the ID returned from the budget endpoint.
        this.changeState({budgetId: '0001'}, () => {
            if (this.props.allowBudgetPrint) {
                this.changeState({step: 'budget-preview', isCreatingBudget: true, budgetCreated: false});
            } else {
                if (this.props.onRegisterFinished) {
                    this.props.onRegisterFinished({
                        budgetId: this.state.budgetId,
                        client: {
                            pin: this.state.pin,
                            entity: this.state.entity,
                            name: this.state.name,
                            phone: this.state.phone,
                            email: this.state.email,
                            activity: this.state.activity,
                            activityName: this.getActivityName(this.state.activity),
                            state: this.state.state,
                            stateName: this.getStateName(this.state.state),
                            city: this.state.city,
                            cityName: this.getCityName(this.state.city),
                            district: this.state.district,
                            street: this.state.street,
                        },
                        company: {
                            companyName: this.state.companyName,
                            companyLogo: this.state.budgetLogo,
                            hqAddress: this.state.hqAddress,
                            hqActivity: this.state.hqActivity
                        }
                    });
                }
            }
        });
    };

    onDocumentLoaded = () => {
        setTimeout(() => {
            this.changeState({isCreatingBudget: false, budgetCreated: true});
        }, 2000);
    };

    printBudget = () => {
        Activity.log(this.props.metadata, 'checkout', this.state.step, 'print', {type: this.props.flow, entity: this.state.entity, shoppingCart: this.props.shoppingCart.toActivityLog()});
        this.changeState({budgetPrinted: false, budgetPrintError: false}, () => {
            return Printer.print(this._containerRef, this._budgetRef)
                .then((status) => {
                    this.changeState({budgetPrinted: true, budgetPrintError: !status});
                })
                .then(() => {
                    setTimeout(() => {
                        this.changeState({budgetPrinted: false, budgetPrintError: false});
                    }, 1000 * 10);
                });
        });
    };

    handleClose = (closeType) => {
        Activity.log(this.props.metadata, 'checkout', closeType, 'close', {type: this.props.flow});
        if (this.props.onClose) this.props.onClose();
    };

    handleBack = () => {
        let newStep;
        switch (this.state.step) {
            case 'identification':
                newStep = 'identification';
                break;
            case 'client-entity-selection':
                newStep = 'identification';
                this.clearAll();
                break;
            case 'client-register':
                if (this.state.allowEntityChange) {
                    newStep = 'client-entity-selection';
                    this.clearRegister();
                } else {
                    newStep = 'identification';
                    this.clearAll();
                }
                break;
            case 'budget-preview':
                break;
            default:
                break;
        }
        Activity.log(this.props.metadata, 'checkout', 'back-button', 'back', {type: this.props.flow});
        this.changeState({step: newStep});
    };

    clearRegister = () => {
        this.changeState({
            entity: undefined,
            phone: undefined,
            email: undefined,
            activity: undefined,
            state: undefined,
            city: undefined,
            district: undefined,
            street: undefined,
            isCreatingBudget: false,
            budgetCreated: false,
            budgetId: undefined,
            budgetPrinted: false,
            budgetPrintError: false
        });
    };

    clearAll = () => {
        this.changeState({
            entity: undefined,
            allowEntityChange: true,
            isLoadingEntity: false,
            retries: 0,
            pin: undefined,
            name: undefined,
            allowNameChange: true,
            phone: undefined,
            email: undefined,
            activities: [],
            activity: undefined,
            state: undefined,
            city: undefined,
            district: undefined,
            street: undefined,
            isCreatingBudget: false,
            budgetCreated: false,
            budgetId: undefined,
            budgetPrinted: false,
            budgetPrintError: false
        });
    };

    renderStep = () => {
        switch (this.state.step) {
            case 'identification':
                return this.renderIdentificationMode();
            case 'client-entity-selection':
                return this.renderEntitySelectionMode();
            case 'client-register':
                return this.renderRegisterMode();
            case 'budget-preview':
                return this.renderPrintMessage();
            default:
                return undefined;
        }
    };

    renderIdentificationMode = () => {
        return (
            <PaymentIdentification
                isLoading={this.state.isLoadingEntity}
                actionButtonBackgroundColor={this.props.actionButtonBackgroundColor}
                actionButtonBorderColor={this.props.actionButtonBorderColor}
                actionButtonLabelColor={this.props.actionButtonLabelColor}
                cancelButtonBackgroundColor={this.props.cancelButtonBackgroundColor}
                cancelButtonBorderColor={this.props.cancelButtonBorderColor}
                cancelButtonLabelColor={this.props.cancelButtonLabelColor}
                customInputOutline={this.props.customInputOutline}
                customInputClearButtonBackgroundColor={this.props.customInputClearButtonBackgroundColor}
                customInputClearButtonLabelColor={this.props.customInputClearButtonLabelColor}
                onSubmit={this.onIdentificationFinished}
                onClose={() => this.handleClose('custom-close-button')}
            />
        );
    };

    renderEntitySelectionMode = () => {
        const options = [
            {
                type: 'Person',
                icon: this.props.personOptionImage || defaultPersonOptionImage,
                label1: 'Persona Natural',
                label2: undefined,
                boldLabel1: true,
                boldLabel2: false,
                labelLineBreak: true,
                waitingMode: false,
                waitingModeIcon: undefined,
                waitingModeLabel: undefined,
                onClick: this.onEntitySelected
            },
            {
                type: 'Company',
                icon: this.props.companyOptionImage || defaultCompanyOptionImage,
                label1: 'Empresa',
                label2: undefined,
                boldLabel1: true,
                boldLabel2: false,
                labelLineBreak: true,
                waitingMode: false,
                waitingModeIcon: undefined,
                waitingModeLabel: undefined,
                onClick: this.onEntitySelected
            }
        ];
        return (
            <PaymentOptionSelection
                title1='Nuevo usuario'
                title2='Selecciona una de las siguientes opciones:'
                titleLineBreak={true}
                options={options}
                cancelButtonBackgroundColor={this.props.cancelButtonBackgroundColor}
                cancelButtonBorderColor={this.props.cancelButtonBorderColor}
                cancelButtonLabelColor={this.props.cancelButtonLabelColor}
                backButtonBackgroundColor={this.props.backButtonBackgroundColor}
                backButtonBorderColor={this.props.backButtonBorderColor}
                backButtonLabelColor={this.props.backButtonLabelColor}
                onClose={() => this.handleClose('custom-close-button')}
                onBack={this.handleBack}
            />
        );
    };

    renderRegisterMode = () => {
        return (
            <PaymentRegister
                title1={this.state.entity === 'Company' ? 'Empresa' : 'Persona Natural'}
                title2='Ingresa tus datos'
                titleLineBreak={true}
                subtitle='(Todos los campos son obligatorios)'
                entity={this.state.entity || 'Person'}
                pin={this.state.pin}
                name={this.state.name}
                allowNameChange={this.state.allowNameChange}
                phone={this.state.phone}
                email={this.state.email}
                states={this.state.states}
                state={this.state.state}
                districts={this.state.districts}
                district={this.state.district}
                cities={this.state.cities}
                city={this.state.city}
                street={this.state.street}
                allActivities={this.state.allActivities}
                activities={this.state.activities}
                activity={this.state.activity}
                actionButtonBackgroundColor={this.props.actionButtonBackgroundColor}
                actionButtonBorderColor={this.props.actionButtonBorderColor}
                actionButtonLabelColor={this.props.actionButtonLabelColor}
                cancelButtonBackgroundColor={this.props.cancelButtonBackgroundColor}
                cancelButtonBorderColor={this.props.cancelButtonBorderColor}
                cancelButtonLabelColor={this.props.cancelButtonLabelColor}
                backButtonBackgroundColor={this.props.backButtonBackgroundColor}
                backButtonBorderColor={this.props.backButtonBorderColor}
                backButtonLabelColor={this.props.backButtonLabelColor}
                customInputOutline={this.props.customInputOutline}
                onPinChange={this.handlePinChange}
                onActivityChange={this.handleActivityChange}
                onNameChange={this.handleNameChange}
                onAddressChange={this.handleAddressChange}
                onStateChange={this.handleStateChange}
                onDistrictChange={this.handleDistrictChange}
                onCityChange={this.handleCityChange}
                onPhoneChange={this.handlePhoneChange}
                onEmailChange={this.handleEmailChange}
                onClose={() => this.handleClose('custom-close-button')}
                onBack={this.handleBack}
                onSubmit={this.onRegisterFinished}
            />
        )
    };

    renderPrintMessage = () => {
        return (
            <PaymentMessage
                isLoading={this.state.isCreatingBudget}
                loaded={this.state.budgetCreated}
                showPreview={true}
                preview={this.renderDocumentPreview()}
                previewSize={Printer.getDocumentSize()}
                title1='Imprime tu cotización'
                title2='Puedes continuar con tu compra en caja'
                loadingMessage='Preparando cotización...'
                step1Message1='Imprime y retira'
                step1Message2=' tu cotización'
                step1Image={this.props.printMessageImage1 || defaultPrintMessageImage1}
                step2Message1='Acércate a caja'
                step2Message2=' si quieres continuar con tu compra'
                step2Image={this.props.printMessageImage2 || defaultPrintMessageImage2}
                errorMessage='Se ha producido un error al imprimir tu cotización'
                actionLabel='Imprimir'
                actionButtonBackgroundColor={this.props.actionButtonBackgroundColor}
                actionButtonBorderColor={this.props.actionButtonBorderColor}
                actionButtonLabelColor={this.props.actionButtonLabelColor}
                cancelButtonBackgroundColor={this.props.cancelButtonBackgroundColor}
                cancelButtonBorderColor={this.props.cancelButtonBorderColor}
                cancelButtonLabelColor={this.props.cancelButtonLabelColor}
                onClose={() => this.handleClose('custom-close-button')}
                onSubmit={this.printBudget}
                submitted={this.state.budgetPrinted}
                submitSuccess={!this.state.budgetPrintError}
                submitSuccessMessage='Tu cotización se está imprimiendo...'
                submitErrorMessage='Se ha producido un error al imprimir tu cotización'
            />
        );
    };

    renderDocumentPreview = () => {
        return (
            <Budget
                documentRef={this._budgetRef}
                title='PRESUPUESTO ELECTRÓNICO'
                id={this.state.budgetId}
                idPrefix='N°'
                image={this.state.budgetLogo}
                issuerName={this.state.companyName}
                issuerAddressLabel='Casa Matriz'
                issuerAddress={this.state.hqAddress}
                issuerActivityLabel='Giro'
                issuerActivity={this.state.hqActivity}
                issuerBranchLabel='Sucursal'
                issuerBranch={this.props.store.address}
                receiverName={this.state.name}
                receiverPinLabel='RUT'
                receiverPin={Pin.format(this.state.pin)}
                receiverActivityLabel='Giro'
                receiverActivity={this.getActivityName(this.state.activity)}
                receiverAddressLabel='Dirección'
                receiverAddress={this.state.street}
                receiverDistrictLabel='Comuna'
                receiverDistrict={this.state.district}
                receiverCityLabel='Ciudad'
                receiverCity={this.getCityName(this.state.city)}
                receiverStateLabel='Región'
                receiverState={this.getStateName(this.state.state)}
                additionalInstructions='Lleve este presupuesto a caja para finalizar la venta'
                expirationMessage='Documento válido sólo por 24 horas desde la fecha de emisión'
                shoppingCart={this.props.shoppingCart}
                showTax={true}
                unitLabel=' c/u'
                onRenderComplete={this.onDocumentLoaded}
            />
        );
    };

    render() {
        return (
            <div ref={this._containerRef} className='signup'>
                {this.isLoadingInitialData() && (
                    <div className='signup-loader'>
                        <DataLoader message={'Cargando datos de registro...'}/>
                    </div>
                )}
                {!this.isLoadingInitialData() && this.renderStep()}
            </div>
        );
    };
}

Signup.propTypes = {
    flow: PropTypes.string,
    initialData: PropTypes.object,
    store: PropTypes.object,
    shoppingCart: PropTypes.object,
    allowBudgetPrint: PropTypes.bool,
    mainClientLogo: PropTypes.string,
    personOptionImage: PropTypes.string,
    companyOptionImage: PropTypes.string,
    printMessageImage1: PropTypes.string,
    printMessageImage2: PropTypes.string,
    actionButtonBackgroundColor: PropTypes.string,
    actionButtonBorderColor: PropTypes.string,
    actionButtonLabelColor: PropTypes.string,
    cancelButtonBackgroundColor: PropTypes.string,
    cancelButtonBorderColor: PropTypes.string,
    cancelButtonLabelColor: PropTypes.string,
    backButtonBackgroundColor: PropTypes.string,
    backButtonBorderColor: PropTypes.string,
    backButtonLabelColor: PropTypes.string,
    customInputOutline: PropTypes.string,
    customInputClearButtonBackgroundColor: PropTypes.string,
    customInputClearButtonLabelColor: PropTypes.string,
    inSmallMode: PropTypes.bool,
    onClose: PropTypes.func,
    onRegisterFinished: PropTypes.func,
    metadata: PropTypes.object
};